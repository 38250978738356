import { useState , useEffect} from "react"
import { Modal,ModalOverlay,ModalContent,ModalHeader,ModalFooter,ModalBody,ModalCloseButton, Text , Button, Image, Center} from '@chakra-ui/react'
import { getAllCategory,updateCategory } from "../../services/category.service"
import { getBasedOnCondition, productsCollectionRef, updateProduct } from "../../services/products.service"
import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from "../../services/Firebase"


export const DeActivateCategoryModal = ({isOpen,onClose,selectedID,setSelectedID,loadCategories,categoriesData}) => {

    const [selectedCategory,setSelectedCategory] = useState()
    const [categoryProducts,setCategoryProducts] = useState([])

    const onCloseClick = () => {
        setSelectedID('')
        onClose()
    }


    useEffect(()=>{
        (async function(){
          const category = categoriesData.find(category => category?.id === selectedID)
          setSelectedCategory(category)
          if(selectedID !== ""){
            const q = query(productsCollectionRef, where("categoryID", "==", selectedID));
            const querySnapshot = await getDocs(q);
            const values = querySnapshot.docs.map(doc => ({...doc.data(),id:doc.id}))
            setCategoryProducts(values)
          }
        })()
      },[selectedID])
      
      const updateProducts = async() => {
        for (const item of categoryProducts) {
          const product = {...item,categoryActive:false}
          await updateProduct(product?.id,product)
        }
      }
      
      const deActivateCategory = async() => {
        const updateActive = {...selectedCategory,active:false}
        await updateCategory(updateActive?.id,updateActive)
        await updateProducts()
        loadCategories()
        onClose()

    }


    return (
        <Modal isOpen={isOpen} onClose={onCloseClick}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Deactivate Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center><Image src={selectedCategory?.categoryImage} width="250px" /></Center>
             <Center><Text>{selectedCategory?.categoryName}</Text></Center>   
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onCloseClick}>
              Close
            </Button>
            <Button variant='ghost' onClick={deActivateCategory}>Deactivate</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}

export const ActivateCategoryModal = ({isOpen,onClose,selectedID,setSelectedID,loadCategories,categoriesData}) => {

    const [selectedCategory,setSelectedCategory] = useState()
    const [categoryProducts,setCategoryProducts] = useState([])


    const onCloseClick = () => {
        setSelectedID('')
        onClose()
    }


    useEffect(()=>{
        (async function(){
          const category = categoriesData.find(category => category?.id === selectedID)
          setSelectedCategory(category)
          const q = query(productsCollectionRef, where("categoryID", "==", selectedID));
          const querySnapshot = await getDocs(q);
          const values = querySnapshot.docs.map(doc => ({...doc.data(),id:doc.id}))
          setCategoryProducts(values)
        })()
    },[selectedID])

    const updateProducts = async() => {
      for (const item of categoryProducts) {
          const product = {...item,categoryActive:true}
          await updateProduct(product?.id,product)
      }
    }


    const activateCategory = async() => {
      const updateActive = {...selectedCategory,active:true}
      await updateCategory(updateActive?.id,updateActive)
      await updateProducts()
      loadCategories()
      onClose() 
    }

  return (
    <Modal isOpen={isOpen} onClose={onCloseClick}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Activate Category</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center><Image src={selectedCategory?.categoryImage} width="250px" /></Center>
             <Center><Text>{selectedCategory?.categoryName}</Text></Center>   
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onCloseClick}>
              Close
            </Button>
            <Button variant='ghost' onClick={activateCategory}>Activate</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}