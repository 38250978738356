import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import { deleteObject, ref } from "firebase/storage"
import { useEffect } from "react"
import { useState } from "react"
import { deleteCarousal } from "../../services/carousal.service"
import { storage } from "../../services/Firebase"


const DeleteCarousalModal = ({isOpen,onClose,selectedID,setSelectedID,imagesData,getImagesData}) => {

    const [selectedCarousal,setSelectedCarousal] = useState()

    useEffect(()=>{
        const findCarousal = imagesData?.find(images => images?.id === selectedID)
        setSelectedCarousal(findCarousal)
    },[selectedID])

    const onCloseClick = () => {
        setSelectedID('')
        onClose()
    }

    const deleteAlreadyCarousal = async() => {
        
        for (const key in selectedCarousal?.images) {
            const imageRef = ref(storage,selectedCarousal?.images[key])
            deleteObject(imageRef).then(()=>{
                console.log('deleted');
            })
        }
        await deleteCarousal(selectedCarousal?.id)
        await getImagesData()
        onClose()
    }



    return (
        <Modal  isOpen={isOpen} onClose={onCloseClick}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Carousal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
                <img src={selectedCarousal?.images?.webImage} style={{marginBottom:'15px'}} />
                <img src={selectedCarousal?.images?.mobileImage} />
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onCloseClick}>
              Close
            </Button>
            <Button variant='ghost' onClick={deleteAlreadyCarousal}>Delete</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}

export default DeleteCarousalModal