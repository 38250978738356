import { Box, Center, Flex, Grid, GridItem, IconButton, Image, Text, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom"
import { useStoreContext } from "../../Context/StoreContext";
import { getAllCategory } from "../../services/category.service";
import DetailModal from "../UI/DetailModal";
import ProductDetail from "../UI/ProductDetail";
import QuickViewDrawer from "./QuickViewDrawer";


const CategoryUI = () => {

    const {productList,dispatch} = useStoreContext();
    const [filteredProducts,setFilteredProducts] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [categoriesData,setCategoriesData] = useState([])
    const [currentCategory,setCurrentCategory] = useState({})
    const params = useParams()

    useEffect(()=>{
        getAllCategories()
    },[])

    const getAllCategories = async() => {
        const getCategories = await getAllCategory()
        const categorylist = getCategories.docs.map(doc => ({...doc.data(),id:doc.id}))
        const findCategory = categorylist.find(category =>  category?.categorySlug === params?.categoryslug)
        setCurrentCategory(findCategory)
        setCategoriesData(categorylist)
    }

    useEffect(()=>{
        const filteredProductsList = productList?.filter(prod => prod?.categoryID?.includes(currentCategory?.id))
        setFilteredProducts(filteredProductsList)
    },[currentCategory])

    return (
        <div>
            <Text fontSize={'2xl'} textAlign="center">{currentCategory?.categoryName}</Text>
            <Grid templateColumns={['repeat(1, 1fr)','repeat(2, 1fr)','repeat(3, 1fr)','repeat(5, 1fr)']} gap={10}>    
	        {filteredProducts?.map(product => ( product?.categoryActive && product?.status !== false &&
                    <ProductDetail quickDisclosureOpen={onOpen}  product={product} key={product.id}  />
                ))}
            </Grid>
            <QuickViewDrawer isOpen={isOpen} onClose={onClose} onOpen={onOpen}  />
        </div>
    )
}

export default CategoryUI
