import { Button } from "@chakra-ui/react";
import React, { useState } from "react";
import CarousalImageCropper from "./CarousalImageCropper";
import FileInput from "./FileInput";
import ImageCropper from "./ImageCropper";

function CarousalImageSelector({setCroppedImage,containerStyles,children,selectedImage,setSelectedImage,currentPage,setCurrentPage,aspectRatio,setAspectRatio,uploadImage}) {
  const [imgAfterCrop, setImgAfterCrop] = useState("");


  // Generating Cropped Image When Done Button Clicked
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = selectedImage;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

    //   const dataURL = canvasEle.toDataURL(
        canvasEle.toBlob((file) => {
          file.name = 'cropped.jpeg';
          setCroppedImage({ file: file, url: URL.createObjectURL(file) })
          uploadImage({ file: file, url: URL.createObjectURL(file) })
        }, 'image/jpeg');

    //   setCroppedImage(dataURL)
      setCurrentPage("img-cropped");
    };
  };

  // Handle Cancel Button Click
  const onCropCancel = () => {
    setCurrentPage("choose-img");
    setSelectedImage("");
  };
//   setCurrentPage("crop-img");
  return (
    <div className="container">
      {currentPage === "choose-img" ? (
        children
      ) : currentPage === "crop-img" ? (
        <CarousalImageCropper
          containerStyles={containerStyles}
          image={selectedImage}
          onCropDone={onCropDone}
          onCropCancel={onCropCancel}
          aspectRatio={aspectRatio}
          setAspectRatio={setAspectRatio}
        />
      ) : (
        <div>
        </div>
      )}
    </div>
  );
}

export default CarousalImageSelector;
