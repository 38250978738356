import { createContext, useContext, useReducer } from "react";
import { STORE_ACTION_TYPES } from "../utils/constants";


export const storeContext = createContext()

const initialState = {
    productList:[],
    cartItems:[],
    totalCartAmount:0,
    selectedProduct:{},
    favourites:[],
    buyingProductFromCart:[],
    totalPriceFromCart:0,
    allPersonalDetails:[
        {username:'Samuel',address:'9 Elk Avenue, Dover,nj, 3801  Manipal  3801',phoneNumber:'08736218312'},
        {username:'Aravind',address:'45 -/ Balanagar, Hyderabad, Andhra Pradesh,500042,India',phoneNumber:'09773837231'},
        {username:'Ashok',address:'87 aundhroad Khadki, Pune, Maharashtra,411003,India',phoneNumber:'0938473473'},
    ],
    allCreditCards:[
        {name:'aravind',cardNumber:'3534353423442435',validThru:'05/20',cvv:'343'},
        {name:'aravind',cardNumber:'3534353423442435',validThru:'05/20',cvv:'343'},
        {name:'aravind',cardNumber:'3534353423442435',validThru:'05/20',cvv:'343s'}
    ]
}

const StoreReducer = (state,action) => {
    switch (action.type) {
        case STORE_ACTION_TYPES.ADD_ALL_PRODUCTS:
            return {...state,productList:action.payload}
        
        case STORE_ACTION_TYPES.ADD_ONE_TO_CART:
            return {...state,cartItems:[...state.cartItems,action.payload]}
        
        case STORE_ACTION_TYPES.INCREASE_ONE_TO_QUANTITY:
            return {...state,cartItems:state.cartItems.map(cart => cart?.selectedVariant?.id === action.payload ? ({...cart,quantity:cart.quantity+1}):cart )}
            // return {...state,cartItems:state.cartItems.map(cart => cart.id === action.payload ? ({...cart,quantity:cart.quantity+1,price:cart.price + findProductIncrease.price}):cart )}
        
        case STORE_ACTION_TYPES.DECREASE_ONE_TO_QUANTITY:
            const findProductDecrease = state.cartItems.find(cart => cart?.selectedVariant?.id === action.payload)
            const newState = {...state,cartItems:state.cartItems.map(cart => cart?.selectedVariant.id === action.payload ?  ({...cart,quantity:cart.quantity-1}): cart)}
            if(findProductDecrease.quantity === 0) {
                return {...newState,cartItems:newState.cartItems.filter(cart => cart?.selectedVariant?.id !== findProductDecrease?.selectedVariant?.id)}
            }
            return newState

        case STORE_ACTION_TYPES.DELETE_ONE_FROM_CART:
            return {...state,cartItems:state.cartItems.filter(item => item?.selectedVariant?.id !== action.payload)}
        
        case STORE_ACTION_TYPES.ADD_SELECTED_PRODUCT:
            return {...state,selectedProduct:action.payload}

        case STORE_ACTION_TYPES.ADD_ONE_TO_FAVOURITE:
            const {ID,src} = action.payload;
            if(src === 'cart') {
                return {...state,productList:state.productList.map(prod => prod.id === ID ? ({...prod,favourite:!prod.favourite}): ({...prod})),cartItems:state.cartItems.map(prod => prod.id === ID ? ({...prod,favourite:!prod.favourite}): ({...prod}))}
            }
            return {...state,productList:state.productList.map(prod => prod.id === ID ? ({...prod,favourite:!prod.favourite}): ({...prod}))}
        
        case STORE_ACTION_TYPES.PRODUCTS_TO_BUY_FROM_CART:
            return {...state,buyingProductFromCart:action.payload}
        
        case STORE_ACTION_TYPES.TOTAL_PRICE_fROM_CART:
            return {...state,totalPriceFromCart:action.payload}

        case STORE_ACTION_TYPES.ADD_ADDRESS_IN_DETAIL:
            return {...state,allPersonalDetails:[...state.allPersonalDetails,action.payload]}
        
        case STORE_ACTION_TYPES.ADD_CREDIT_CARD:
            return {...state,allCreditCards:[...state.allCreditCards,action.payload]}
        default:
            return state
    }
}

const StoreProvider = ({children}) => {

    const [state,dispatch] = useReducer(StoreReducer,initialState)


    return (
        <storeContext.Provider value={{
            productList:state.productList,
            cartItems:state.cartItems,
            totalCartAmount:state.totalCartAmount,
            selectedProduct:state.selectedProduct,
            favourites:state.favourites,
            buyingProductFromCart:state.buyingProductFromCart,
            totalPriceFromCart:state.totalPriceFromCart,
            addedPrice:state.addedPrice,
            allCreditCards:state.allCreditCards,
            allPersonalDetails:state.allPersonalDetails,
            dispatch
        }}>
            {children}
        </storeContext.Provider>
    )
}

export default StoreProvider

export const useStoreContext = () =>{
    return useContext(storeContext)
}