import React from 'react';
import ReactDOM from 'react-dom/client';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './index.css';
import '@fontsource/aileron/400.css'
import '@fontsource/gelasio/400.css'
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, extendTheme,theme as base } from '@chakra-ui/react'

const theme = extendTheme({
  fonts: {
    heading: `'gelasio, ${base.fonts?.heading}`,
    body: `'aileron, ${base.fonts?.body}`,
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ChakraProvider theme={theme}>
        <App />
    </ChakraProvider>
  </BrowserRouter>
);