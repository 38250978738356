import { createContext, useContext, useEffect, useState } from "react";
import { 
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithPopup
 } from 'firebase/auth'
import { auth } from "../services/Firebase";

export const authContext = createContext();


const initialState = {
    user:null,
    isUserLoggedIn:false
}

export const AuthProvider = ({children}) => {

    const [user,setUser] = useState({email:''})

    const signUp = (email,password) => {
        return createUserWithEmailAndPassword(auth,email,password)
    }

    const login = (email,password) => {
        return signInWithEmailAndPassword(auth,email,password)
    }

    

    const logout = () => {
        return signOut(auth)
    }

    useEffect(()=>{
        const unsubscribe = onAuthStateChanged(auth,(currentUser)=>{
            setUser(currentUser)
        })
        return ()=>{
            unsubscribe();
        }
    },[])

    console.log('user =>',user);

    return (
        <authContext.Provider value={{signUp,login,user,logout}}>
            {children}
        </authContext.Provider>
    )
}

export const useAuth = () =>{
    return useContext(authContext)
}