import { Box, Button, Container, Flex, IconButton, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr , Modal,ModalOverlay,ModalContent,ModalHeader, ModalFooter,ModalBody, ModalCloseButton, useDisclosure } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Link as routeLink } from "react-router-dom"
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai"
import { Link } from "react-router-dom"
import { getAllProducts, updateProduct } from "../../services/products.service"
import Sidebar from "../UI/Sidebar"
        

const AdminScreen = () => {

    const [productList,setProductList] = useState([])
    const deleteModalDisclosure = useDisclosure()
    const [productID,setProductID] = useState('')

    useEffect(()=>{
        getProducts()
    },[])

    const getProducts = async() => {
        const getProducts = await getAllProducts()
        setProductList(getProducts.docs.map(doc => ({...doc.data(),id:doc.id})))
    }    

    return (
        <Sidebar>
            <Container maxW={1200} mt="12" >
            <Flex alignItems={'center'} justifyContent="flex-end">
                <Button>Products</Button>
                <Button ml="4" leftIcon={<AiOutlinePlus />} as={Link} to='/addproduct'>New Product</Button>
            </Flex>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Inventory</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                        {productList.map(product => ( product?.status !== false  &&
                            <Tr>
                                <Td>{product.Name}</Td>
                                <Td>{product.inventory}</Td>
                                <Td>
                                    <IconButton icon={<AiOutlineEdit />} as={routeLink} to={`/adminscreen/editproduct/${product?.id}`} />
                                    {/* <IconButton icon={<AiOutlineDelete />} onClick={()=>publishedToggle(product?.id)} /> */}
                                    <IconButton icon={<AiOutlineDelete />} onClick={()=>{setProductID(product?.id);deleteModalDisclosure?.onOpen()}} />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                </TableContainer>
        </Container>
        <DeleteProductModal isOpen={deleteModalDisclosure?.isOpen} onClose={deleteModalDisclosure?.onClose} productID={productID} setProductID={setProductID} productList={productList} getProducts={getProducts} />
        </Sidebar>
    )
}

export default AdminScreen


const DeleteProductModal = ({isOpen,onClose,productID,setProductID,productList,getProducts}) => {

    const publishedToggle = async() => {
        const product = productList.find(prod => prod?.id === productID)
        const publishToggle = {...product,status:false}
        await updateProduct(publishToggle?.id,publishToggle)
        setProductID('')
        getProducts()
        onClose()
    }

    const onCloseClick = () => {
        setProductID('')
        onClose()
    }


    return (
        <Modal isOpen={isOpen} onClose={onCloseClick}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you'd like to delete the product?</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onCloseClick}>
              Cancel
            </Button>
            <Button variant='ghost' onClick={()=>{publishedToggle()}}>Ok</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}