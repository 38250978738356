import { Box, Button, Center, Flex, GridItem, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useStoreContext } from "../../Context/StoreContext";
import { STORE_ACTION_TYPES } from "../../utils/constants";


const ProductDetail = ({product,quickDisclosureOpen}) => {

    const {productList,dispatch} = useStoreContext()


    const onSelectProduct = (prodID) => {
        const findProduct = productList.find(prod => prod.id === prodID)
        dispatch({type:STORE_ACTION_TYPES.ADD_SELECTED_PRODUCT,payload:findProduct})
    }

    return (
        <GridItem>
            <Box pos='relative' width={'100%'} className="product-image-container">
                <Link to={`/productDetail/${product?.id}`}>
                    <Image  src={product?.images?.[0]} loading="lazy" objectFit="contain"  width={'100%'} height="300px" />
                </Link>
                <Flex display={'none'} width="100%" gap={6} justifyContent="center" className="icon-button-container">
                    <Button width={'full'} colorScheme="blackAlpha"  onClick={()=>{onSelectProduct(product?.id) ; quickDisclosureOpen()}}>Quick View</Button>
                </Flex>
            </Box>
            <Box p="6px">
                <Center><Text>{product?.Name}</Text></Center>
                <Center><Text>{product?.categoryDetails?.categoryName}</Text></Center>
                {/* <Center><StarRating value={product?.rating?.rate} /></Center> */}
                <Center><Text>&#8377; {product?.prices?.[0]?.RUPEE?.price}</Text></Center>
            </Box>
        </GridItem>
    )
}

export default ProductDetail