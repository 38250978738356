import { Box, Button, Flex, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react"
import { useState } from "react";
import { useEffect } from "react"
import { useStoreContext } from "../../Context/StoreContext"


const DetailModal = ({isOpen,onClose,onOpen,prodID}) => {
  const { selectedProduct } = useStoreContext();

    return (
      <>
        <Modal isOpen={isOpen} size={['4xl']} key={selectedProduct.id} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modal Title</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex>
                <Image src={selectedProduct.image} objectFit={'contain'} width={'250px'} />

                <Box p="7">
                  <Text my="10">title ={selectedProduct.title}</Text>
                  <Text my="10">category ={selectedProduct.category}</Text>
                  <Text my="10">description = {selectedProduct.description}</Text>
                  <Text my="10">price = {selectedProduct.price}</Text>
                </Box>
              </Flex>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Buy
              </Button>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default DetailModal