import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    doc,
    where,
    query,
    orderBy,
    limit,
    deleteDoc
} from 'firebase/firestore'
import { db } from './Firebase'

const userCollectionRef = collection(db,'user-profile')

export const addUserProfile = userData => {
    return addDoc(userCollectionRef,userData)
}

export const updateUserProfile = (id,newUserData) => {
    const profileDoc = doc(db,'user-profile',id)
    return updateDoc(profileDoc,newUserData)
}

export const getAllUserProfile = () => {
    return getDocs(userCollectionRef)
}

export const deleteUserProfile = (id) => {
    const profileDoc = doc(db,'user-profile',id)
    return deleteDoc(profileDoc)
}