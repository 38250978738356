import { Alert, AlertDescription, AlertIcon, Box, Button, HStack, IconButton, Image, Input, Select, Spacer, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tr, useDisclosure, useStatStyles, useToast } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { addProduct } from "../../services/products.service"
import { ref,uploadBytesResumable,getDownloadURL, deleteObject } from 'firebase/storage'
import {FcAddImage} from 'react-icons/fc'
import {AiFillCloseCircle} from 'react-icons/ai'
import { storage } from "../../services/Firebase"
import { v4 as uuv4} from 'uuid'
import { async } from "@firebase/util"
import Sidebar from "../UI/Sidebar"
import AddCategoryModal from "../Admin/AddCategoryModal"
import { useEffect } from "react"
import { getAllCategory } from "../../services/category.service"
import { useNavigate } from "react-router-dom"
import AddVariantImages from "../Admin/AddVariantImages"
import { BiImageAdd } from "react-icons/bi"

const AddProduct = () => {

    const [productDetails,setProductDetails] = useState({
        Name:"",
        description:"",
        status:true,
    })
    const [singlePrice,setSingleprice] = useState({"RUPEE":{currency:'Indian Rupee',price:0}});
    const [singleVariant,setSingleVariant] = useState({variantName:'',variantValue:''})
    const [pricesList,setPricesList] = useState([]);
    const [variantList,setVariantList] = useState([])
    const [variantListObj,setVariantListObj] = useState({})
    const [imageLinkList,setImageLinkList] = useState([])
    const [uniqueProducts,setUniqueProducts] = useState([])
    const [categoriesData,setCategoriesData] = useState([])
    const [singleVariantImages,setSingleVariantImages] = useState([])
    const [selectedVariantId,setSelectedVariantId] = useState('')
    const [error,setError] = useState()
    const VariantImagesModalDisclosure = useDisclosure()
    const [category,setCategory] = useState({})
    const navigate = useNavigate()
    const categoryToast = useToast()

    useEffect(()=>{
        (async function(){
            const getCategories = await getAllCategory()
            const categorylist = getCategories.docs.map(doc => ({...doc.data(),id:doc.id}))
            setCategoriesData(categorylist)
        })()
    },[])

    const handlePricesChange = (e)=>{
        setSingleprice(prevState => ({
            RUPEE:{...prevState.RUPEE,price:e.target.value}
        }))
    }

    const handleVariantChange = e =>{
        setSingleVariant(prevState => ({
            ...prevState,
            [e.target.id]:e.target.value
        }))
    }

    function calculateVariant(ObjVar) {

        let variants =[]
        for (let [key,values] of Object.entries(ObjVar)){
            variants.push(values.map(data => ({[key]:data})))
        }
        variants = variants.reduce((a,b)=> a.flatMap(d => b.map(e => ({...e,...d}))))
        return variants

    }

    useEffect(()=>{
        const uniqueProductsList = uniqueProducts.map(unique => ({...unique,variantTitle:productDetails?.Name+' -'+Object.keys(unique?.variantDetails)?.map(vari => ' '+unique?.variantDetails?.[vari])}))
        setUniqueProducts(uniqueProductsList)
    },[productDetails?.Name])

    const addVariantLists = () => {
        if(singleVariant?.variantName === '' || singleVariant?.variantValue === ''){
            return
        }
        const listOfVariants = singleVariant?.variantValue.split(',')
        const variantObj = {...variantListObj,[singleVariant?.variantName]:listOfVariants}
        setVariantListObj(variantObj)
        const getVariantList = calculateVariant(variantObj)
        const uniqueProductsList = getVariantList.map(variant => ({variantDetails:variant,variantTitle:productDetails?.Name+' -'+Object.keys(variant).map(vari => ' '+variant[vari]),images:[],id:uuv4(),variantPrice:'',quantity:0}))
        setUniqueProducts(uniqueProductsList)
        const newVariant = {variantName:singleVariant?.variantName,variantValues:listOfVariants}
        setVariantList(prevState => [...prevState,newVariant])
        setSingleVariant({variantName:'',variantValue:''})
    };


    const onChangeCategory = (id) =>{
        const findCategory = categoriesData.find(category => category.id === id)
        setCategory(findCategory)
    }

    const handleSubmit = async(e) => {
        e.preventDefault()

        const checkEmptyValues = uniqueProducts.every(item => item.variantTitle && item.variantPrice && item.images.length > 0)

        if(category === {}){
            navigate('/adminscreen/managecategory')
            categoryToast({
                title: 'Add category',
                position:'top-right',
                description: "add categiry in manage categories",
                status: 'success',
                duration: 300,
                isClosable: true,
              })
        }

        if(checkEmptyValues && imageLinkList.length > 0 && productDetails?.Name && singlePrice?.RUPEE.price !== 0){           
            const productDetail = {Name:productDetails?.Name,description:productDetails?.description,prices:[singlePrice],images:imageLinkList,status:productDetails?.status,variants:uniqueProducts,variantLists:variantList,categoryID:category?.id,categoryActive:category?.active}
            try {
                await addProduct(productDetail)
                setProductDetails({Name:'',description:'',status:true})
                setImageLinkList([]);
                setSingleprice({"RUPEE":{currency:'Indian Rupee',price:0}})
                setPricesList([]);
                setVariantList([]);
                setVariantListObj({});
                setCategory({})
                setUniqueProducts([])
            } catch (error) {
                setError(error.message)
            }
        }else{
            categoryToast({
                title: 'fill in all details',
                position:'top-right',
                status: 'error',
                duration: 300,
                isClosable: true,
            })
        }

    }


    const handleProductDetails = e =>{
        setProductDetails(prevState=> ({
            ...prevState,
            [e.target.id]:e.target.value
        }))
    }

    const deleteImage = (filename) => {
        const imageRef = ref(storage,filename);

        // Delete the file
        deleteObject(imageRef).then(() => {
            setImageLinkList(prevState => {
                const filterimages = prevState.filter(data => data !== filename)
                return filterimages
            })
        }).catch((error) => {
            console.log(error.message)
        });
    }

    const ImageSubmit = (e) => {
        const imgList = [...e.target.files];
        for (const key of imgList) {
            handleImageUpload(key)
        }
    }


    const handleImageUpload = (imgSrc) => {
        const newImgLink = imgSrc
        const filename =  new Date().getTime() + newImgLink.name
        const storageref = ref(storage,filename);
        const uploadImagetask = uploadBytesResumable(storageref,newImgLink)

        uploadImagetask.on('state_changed', 
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case 'paused':
                console.log('Upload is paused');
                break;
            case 'running':
                console.log('Upload is running');
                break;
            }
        }, 
        (error) => {
            console.log(error)
        }, 
        () => {
            getDownloadURL(uploadImagetask.snapshot.ref).then((downloadURL) => {
                setImageLinkList(prevState => [...prevState,downloadURL])
                // handleAddImages(downloadURL,varID)
            });
        }
        );
    }

    const handleUniqueChange = (e,prodId) => {
        const deUniqueProducts = uniqueProducts.map(prod => prod?.id === prodId ? ({...prod,[e.target.id]:e.target.value}):({...prod}))
        setUniqueProducts(deUniqueProducts)
    }

    const deleteImageFromVariantTable = (prodId,imgSrc) => {
        const newList = uniqueProducts?.map(prod => prod?.id === prodId ? ({...prod,images:prod.images.filter(img => img !== imgSrc)}): ({...prod}))
        setUniqueProducts(newList)
    }

    const variantsImagesSubmit = () => {
        const imageChangeList = uniqueProducts?.map(prod => prod?.id === selectedVariantId ? ({...prod,images:singleVariantImages}):({...prod}))
        setUniqueProducts(imageChangeList)
        setSelectedVariantId('')
        setSingleVariantImages([])
        VariantImagesModalDisclosure?.onClose()
    }

    const onVariantImageModal = (varId) => {
        setSelectedVariantId(varId)
        const findVariant = uniqueProducts?.find(prod => prod?.id === varId)
        setSingleVariantImages(findVariant?.images)
        VariantImagesModalDisclosure?.onOpen()
    }
    
    return (
        <Sidebar>
            <Box maxW='1000' mx={"auto"}  backgroundColor="whitesmoke" p='3'>
                {error && <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>{error}</AlertDescription>    
                </Alert>}
                <HStack>
                    <Text fontSize='2xl' fontWeight='bold'>General</Text>
                    <Spacer />
                    <Button colorScheme='teal' onClick={(e)=>handleSubmit(e)}>Submit Product</Button>
                </HStack>
                <Input w="350px" placeholder='Product Name' type="text" size='md' my="3" id="Name" value={productDetails.Name} onChange={e => handleProductDetails(e)} />
                <Stack direction={'row'} alignItems="center" my="3">
                    <Select width={['100%','40%']} value={category?.id} onChange={e => onChangeCategory(e.target.value)}>
                        <option disabled selected >Select Category</option>
                        {categoriesData?.map(category => ( category.active &&
                            <option value={category.id}>{category.categoryName}</option>
                        ))}
                    </Select>
                </Stack>
                <Textarea value={productDetails.description} id="description" placeholder="Description" onChange={e=>handleProductDetails(e)}></Textarea> 
                <HStack align='center' my='3'>
                    <Text>Product with variant</Text>   
                    <Spacer />
                    <Button onClick={addVariantLists} colorScheme="orange">Add Variant</Button>
                </HStack>
                <HStack w="100%">
                    <Box p={5} w="30%" shadow='md' borderWidth='1px'>
                        <Text>Variant</Text>
                        <Input placeholder="Size" id="variantName" type='text' value={singleVariant?.variantName} onChange={e=> handleVariantChange(e)}   />
                    </Box>
                    <Box shadow='md' w="70%" borderWidth='1px' p={5}>
                        <Text>Value</Text>
                        <Input placeholder='Small, Medium, Large' id="variantValue" type="text" value={singleVariant?.variantValue} onChange={e=> handleVariantChange(e)} />
                    </Box>
                </HStack>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                {Object.keys(variantList[0] || {})?.map(elem=>(
                                    <Th>{elem}</Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {variantList.map(variant => (
                                <Tr>
                                    {Object.keys(variantList[0] || {})?.map(elem => (
                                        <Td>{variant[elem]}</Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            <Box maxW='1000' mx={"auto"}  backgroundColor="whitesmoke" mt="5" p="3">
                <Text fontSize='2xl' my="3" fontWeight='bold'>Pricing</Text>
                <HStack align="center" my='3'>
                    <Text fontSize='md' my="3">Give products a price for each of the currencies that you sell in</Text>
                    <Spacer />
                </HStack>
                <HStack w="100%">
                    <Box p={5} w="30%" shadow='md' borderWidth='1px'>
                        <Text>Currency</Text>
                        <Box display={'flex'} alignItems="center" width={'100%'}>
                            <Text border={'1px'} px="8px" borderRadius={'10px'} py="8px" borderStyle="solid" width={'100%'} borderColor={'gray.300'}>{singlePrice['RUPEE']?.currency} &#8377;</Text>
                        </Box>
                    </Box>
                    <Box shadow='md' w="70%" borderWidth='1px' p={5}>
                        <Text>Amount</Text>
                        <Input placeholder='Enter Amount' id="price" type="number" value={singlePrice.RUPEE.price} onChange={e=> handlePricesChange(e)} />
                    </Box>
                </HStack>
            </Box>
            <Box maxW='1000' mx={"auto"}  backgroundColor="whitesmoke" mt="5" p="3">
                <HStack>
                    <Text>images for the product</Text>
                    <label className="image-picker"><Input type="file" onChange={(e)=>ImageSubmit(e)} multiple />add Images</label>

                    <Box display='flex' gap='5px'>
                        {imageLinkList.map(srcLink => (
                            <span className="img-container">
                            <AiFillCloseCircle className="close-icon" onClick={()=>deleteImage(srcLink)} />
                            <Image src={srcLink} width="50px" height="50px" />
                        </span>
                        ))}
                    </Box>
                </HStack>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Td>S.no</Td>
                                <Td>Variant Details</Td> 
                                <Td>Variant Name</Td>
                                <Td>Variant Price</Td>
                                <Td>Quantity</Td>
                                <Td>Images</Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {uniqueProducts.map((Prod,id) => (
                                <Tr key={Prod?.id}>
                                    <Td>{id+1}</Td>
                                    <Td>{Object.keys(Prod?.variantDetails || {}).map(elem => (
                                        <span>{Prod?.variantDetails[elem]} </span>
                                    ))}</Td>
                                    <Td><Input value={Prod?.variantTitle} id="variantTitle" type="text"  onChange={e => handleUniqueChange(e,Prod?.id)} /></Td>
                                    <Td><Input value={Prod?.variantPrice} id="variantPrice" type="number" onChange={e => handleUniqueChange(e,Prod?.id)} /></Td>
                                    <Td><Input value={Prod?.quantity} id="quantity" type="number" min="0"  onChange={e => handleUniqueChange(e,Prod?.id)} /></Td>
                                    <Td position='relative' display={'flex'} gap="3px" alignItems={'center'} >
                                    <IconButton icon={<BiImageAdd />} onClick={()=>onVariantImageModal(Prod?.id)} />
                                        {Prod?.images?.map(data => (
                                        <span className="img-container">
                                            <img width='100px' height="30px" src={data} />
                                            <AiFillCloseCircle className="close-icon" onClick={()=>deleteImageFromVariantTable(Prod?.id,data)} />
                                        </span>))}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>

            <AddVariantImages isOpen={VariantImagesModalDisclosure.isOpen} onClose={VariantImagesModalDisclosure.onClose} allImages={imageLinkList} imagesSubmit={variantsImagesSubmit} setVariantID={setSelectedVariantId} singleVariantImages={singleVariantImages} setSingleVariantImages={setSingleVariantImages} />
        </Sidebar>
    )
}

export default AddProduct
