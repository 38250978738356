import { Box, CloseButton, Drawer, DrawerContent,Link, Flex, Icon, IconButton,  Text, useColorModeValue, useDisclosure } from "@chakra-ui/react"
import {
    FiHome,
    FiTrendingUp,
    FiMenu,
  } from 'react-icons/fi';
import { Link as routerLink } from "react-router-dom";
const LinkItems = [
    { name: 'Home', icon: FiHome,link:'/adminscreen' },
    { name: 'Add Product', icon: FiTrendingUp,link:'/addproduct' },
    { name: 'Manage Categories',icon:FiTrendingUp , link:'/adminscreen/managecategory'},
    { name: 'Edit Carousal',icon: FiTrendingUp,link:'/adminscreen/editcarousal'},
    { name: 'Pages' ,icon: FiTrendingUp,link:'/adminscreen/pages'}
  ];


  export default function Sidebar({ children }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          {children}
        </Box>
      </Box>
    );
  }
  
  
  const SidebarContent = ({ onClose, ...rest }) => {
    return (
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        borderRight="1px"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        w={{ base: 'full', md: 60 }}
        pos="fixed"
        h="full"
        {...rest}>
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <Text fontSize="2xl" display={{base:'block',md:'none'}} fontFamily="monospace" fontWeight="bold">
            EdgeScale
          </Text>
          <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
        </Flex>
        {LinkItems.map((link) => (
          <NavItem key={link.name} linkSrc={link.link} icon={link.icon}>
            {link.name}
          </NavItem>
        ))}
      </Box>
    );
  };
  
  
  const NavItem = ({ icon, linkSrc,children, ...rest }) => {
    return (
      <Link as={routerLink} to={linkSrc} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: 'cyan.400',
            color: 'white',
          }}
          {...rest}>
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Link>
    );
  };
  
  const MobileNav = ({ onOpen, ...rest }) => {
    return (
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 24 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue('white', 'gray.900')}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        justifyContent="flex-start"
        {...rest}>
        <IconButton
          variant="outline"
          onClick={onOpen}
          aria-label="open menu"
          icon={<FiMenu />}
        />
      </Flex>
    );
  };
