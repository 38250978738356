import { Text } from "@chakra-ui/react"
import { useEffect } from "react"
import { useState } from "react"
import { getAllFooter } from "../../services/footer.service"
import DOMPurify from "dompurify"

const AboutUs = () => {

    const [getDatas,setGetDatas] = useState({})

    useEffect(()=>{
        getAllDatas()
    },[])
    async function getAllDatas(){
        const wholeDatas = await getAllFooter()
        const datas = wholeDatas.docs.map(doc => ({...doc.data(),id:doc.id}))
        setGetDatas(datas[datas.length-1])
    }

    function createMarkup(html) {
        return {
          __html: DOMPurify.sanitize(html)
        }
    }

    console.log('getDatas =>',getDatas);

    return (
        <div>
            <Text textAlign={'center'} fontSize="2xl" mb="20px">About Us</Text>
            <Text textAlign={'center'} dangerouslySetInnerHTML={createMarkup(getDatas?.about)}></Text>
        </div>
    )
}

export default AboutUs