import { Box, Button, FormControl, FormLabel, HStack, Image, Input, Spacer, Text, Textarea, useToast } from "@chakra-ui/react"
import { serverTimestamp } from "firebase/firestore"
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { useEffect } from "react"
import JoditEditor from "jodit-react"
import { useRef } from "react"
import { useState } from "react"
import { storage } from "../../services/Firebase"
import { addFooter, getAllFooter, updateFooter } from "../../services/footer.service"
import { addStoreData } from "../../services/store.service"
import Sidebar from "../UI/Sidebar"


const Pages = () => {
    

    const editor = useRef(null)
    const toast = useToast()
    const [details,setDetails] = useState({
        about:'',
        termsAndCondition:'',
        contactUsEmail:'',
        storeName:'',
        storeLogo:''
    }) 


    useEffect(()=>{
        getAllDatas()
    },[])

    async function getAllDatas(){
        const wholeDatas = await getAllFooter()
        const datas = wholeDatas.docs.map(doc => ({...doc.data(),id:doc.id}))
        setDetails(datas[datas.length-1])
    }

    const handleSubmit = async() => {

        if(details?.created){
            const detailsObject = {...details}
            await updateFooter(details?.id,detailsObject)
            toast({
                title: 'footer updated',
                status: 'success',
                duration: 300,
                isClosable: true,
                position:'top-right'
            })
        }else {
            if(details?.about !== '' && details?.termsAndCondition !== '' && details?.contactUsEmail !== '' && details?.storeName !== '' && details?.storeLogo !== ''){
                const newFooter = {about:details?.about,contactUsEmail:details?.contactUsEmail,termsAndCondition:details?.termsAndCondition,storeName:details?.storeName,storeLogo:details?.storeLogo,created:serverTimestamp()}
                await addFooter(newFooter)
                toast({
                    title: 'New footer added',
                    status: 'success',
                    duration: 300,
                    isClosable: true,
                    position:'top-right'
                })
            }else{
                toast({
                    title: 'fill in pages details',
                    status: 'error',
                    duration: 300,
                    isClosable: true,
                    position:'top-right'
                })
            }
        }

    }

    const handleImageUpload = (e) => {
        const newImgLink = e.target.files[0]
        const filename =  new Date().getTime() + newImgLink.name
        const storageref = ref(storage,filename);
        const uploadImagetask = uploadBytesResumable(storageref,newImgLink)

        uploadImagetask.on('state_changed', 
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case 'paused':
                console.log('Upload is paused');
                break;
            case 'running':
                console.log('Upload is running');
                break;
            }
        }, 
        (error) => {
            console.log(error)
        }, 
        () => {
            getDownloadURL(uploadImagetask.snapshot.ref).then((downloadURL) => {

                setDetails(prev => ({...prev,storeLogo:downloadURL}))
            });
        }
        );
    }

    console.log(details);


    return (
        <Sidebar>
            <Box>
                <HStack mb="30px">
                    <Text fontSize={'2xl'}>Upload Logo, Store Name And Pages</Text>
                    <Spacer />
                    <Button onClick={handleSubmit} alignSelf="flex-end" colorScheme="blue">Submit</Button>
                </HStack>
                <label  className="logo-image-input"><input type="file" onChange={handleImageUpload} />Add Logo</label> <br />

                {details?.storeLogo !== '' && (
                    <Image my="20px" src={details?.storeLogo} width="300px" />
                )}
                <Input my="30px" width={["100%","30%"]} type="text" id="storeName" placeholder="Store Name" value={details?.storeName} onChange={e => setDetails(prev => ({...prev,storeName:e.target.value}))} />
            </Box>
            <Box my="50px">
                <Text>About us</Text>
                <JoditEditor
                    ref={editor}
                    value={details?.about}
                    onChange={newContent => setDetails(prev => ({...prev,about:newContent}))}
                />
            </Box>
            <Box>
                <Text>Terms and condition</Text>
                <JoditEditor 
                    ref={editor}
                    value={details?.termsAndCondition}
                    onChange={newContent => setDetails(prev => ({...prev,termsAndCondition:newContent}))}
                />
            </Box>
            <Box mt="50px">
                <Text>Contact us</Text>
                <FormControl>
                    <Input placeholder="contact email" id="contactUsEmail" value={details?.contactUsEmail} onChange={e => setDetails(prev => ({...prev,contactUsEmail:e.target.value}))} type="email" />
                </FormControl>
            </Box>
        </Sidebar>
    )
}

export default Pages