import { Grid, useDisclosure } from "@chakra-ui/react";
import { useStoreContext } from "../../Context/StoreContext"
import DetailModal from "../UI/DetailModal";
import ProductDetail from "../UI/ProductDetail";


const Favourites = () => {

    const {productList} = useStoreContext()
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <Grid templateColumns={['repeat(1, 1fr)','repeat(2, 1fr)','repeat(3, 1fr)','repeat(5, 1fr)']} gap={10}>    
                {productList.map(product => (
                    product.favourite ? <ProductDetail product={product} onOpen={onOpen} key={product.id} /> : <></>             
                ))}
            <DetailModal isOpen={isOpen} onClose={onClose} onOpen={onOpen}  />
        </Grid>
    )
}

export default Favourites