
import { Box, Button } from '@chakra-ui/react';
import { useState } from 'react';
import Cropper from 'react-easy-crop';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const CarousalImageCropper = ({image,onCropDone,onCropCancel,containerStyles,aspectRatio,setAspectRatio}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onAspectRatioChange = (event) => {
    setAspectRatio(event.target.value);
  };

  return (
    <Box>
      <Box display={'flex'} justifyContent="center" alignItems={'center'} height="400px" mb="20px">
        <Cropper
          image={image}
          aspect={aspectRatio}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: containerStyles,
          }}
        />
        <Box width="80%" height={"100%"} display={'flex'} alignItems="flex-end" justifyContent={'flex-end'}>

        <Button mx="14px" colorScheme={'orange'} onClick={onCropCancel}>
          Cancel
        </Button>

        <Button
          colorScheme={'green'}
          onClick={() => {
            onCropDone(croppedArea);
          }}
        >
          Done
        </Button>
      </Box>
      </Box>

      
    </Box>
  );
}

export default CarousalImageCropper