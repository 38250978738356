import { Alert, AlertIcon, Box, Button, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, Textarea } from "@chakra-ui/react"
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { useState } from "react"
import { BiImageAdd} from "react-icons/bi"
import { storage } from "../../services/Firebase"

const AddCategoryModal = ({isOpen,onOpen,onClose,children,onSubmitCategory,imageLink,deleteImage}) => {

    const onCloseClick = () => {
      console.log('clickeddddd');
      if(imageLink !== ''){
        deleteImage(imageLink)
        onClose()
      }
      onClose()
    }

    return (
      <>
        <Modal isOpen={isOpen} onClose={()=>onCloseClick()} size="4xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign={'center'}>Add Category</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {children}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={onSubmitCategory} variant='ghost'>Add</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  export default AddCategoryModal
