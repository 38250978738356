import {
    collection,
    getDoc,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    orderBy,
    query
} from 'firebase/firestore'
import { db } from './Firebase'


const carousalCollectionRef = collection(db,"carousal")

export const addCarousalLists = newCarousal => {
    return addDoc(carousalCollectionRef,newCarousal)
}

export const deleteCarousal = (id) =>{
    const carousalDoc = doc(db,"carousal",id)
    return deleteDoc(carousalDoc)
}

export const getAllCarousal = () => {
    return getDocs(query(carousalCollectionRef,orderBy('created')))
}

export const getCarousal = (id) => {
    const carousalDoc = doc(db,"carousal",id)
    return getDoc(carousalDoc)
}


export const updateCarousal = (id,updatedCarosual) => {
    const carousalDoc = doc(db,"carousal",id)
    return updateDoc(carousalDoc,updatedCarosual)
}

export const recentAddedCarousal = () => {
    db.collection("carousal").where("state", "==", "CA")
    .onSnapshot(function(snapshot) {
        snapshot.docChanges().forEach(function(change) {
            if (change.type === "added") {
                console.log("New city: ", change.doc.data());
                //do what you want here!
                //function for rearranging or sorting etc.
            }
            if (change.type === "modified") {
                console.log("Modified city: ", change.doc.data());
            }
            if (change.type === "removed") {
                console.log("Removed city: ", change.doc.data());
            }
        });
    });
}