import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    doc,
    deleteDoc,
    getDoc,
    query,
    orderBy
} from 'firebase/firestore'
import { db } from './Firebase'

const footerCollectionRef = collection(db,'footer')
const categoryCollectionRef = collection(db,'category')

export const addFooter = newFooter => {
    return addDoc(footerCollectionRef,newFooter)
}

export const deleteFooter = id => {
    const footerDoc =  doc(db,'footer',id)
    return deleteDoc(footerDoc)
}

export const getAllFooter = () =>{
    return getDocs(query(footerCollectionRef,orderBy('created')))
}

export const updateFooter = (id,updatedFooter) => {
    const footerDoc = doc(db,'footer',id)
    return updateDoc(footerDoc,updatedFooter)
}

export const getSingleFooter = id => {
    const footerDoc = doc(db,'footer',id)
    return getDoc(footerDoc)
}

export const getAllCategory = () => {
    return getDocs(categoryCollectionRef)
}