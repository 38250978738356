import { IoMdAddCircleOutline } from "react-icons/io"


// export const CreateCreditCard = () => {

//     return (
//         <>
//             <form>
//                 <div className="form-control"></div>
//             </form>
//         </>
//     )
// }

export const CreditCard = () => {

    return (
        <div className="credit-card">
             <h3><i>visa</i></h3>
            <div className="top">
                <h1><i>visa</i></h1>
                <h2>credit card</h2>
            </div>

            <div className="mid">
                <h2>card number</h2>
                <div className="card-number">
                    <span>4929</span>
                    <span>1245</span>
                    <span>7854</span>
                    <span>0050</span>
                </div>
            </div>

            <div className="bottom">
                <div className="card-holder">
                    <h2>card holder</h2>
                    <span>kishore das</span>
                </div>   
                <div className="express">
                    <h2>express</h2>
                    <span>04</span> /
                    <span>18</span>
                </div>      
                <div className="cvv">
                    <h2>cvv</h2>
                    <span>7</span>
                    <span>5</span>
                    <span>1</span>
                </div>
            </div>

        </div>

    )
}

export const AtmCard = ({cardData}) => {

    return (
        <div className='atm-card'>
            <div className='top-block'>
                <div className='atm-card-chip'>
                    <i className="icon-credit-card icon-3x"></i>
                </div>
                <span className='atm-card-name'>
                    Oxyzen Pay
                </span>
            </div>
            <div className='atm-card-number'>
                <p>{cardData.cardNumber}</p>
            </div>
            <div className='bottom-block'>
                <div className='balance'>
                    <div>{cardData.name}</div>
                    <div className='atm-card-balance'>
                       {cardData.validThru}
                    </div>
                </div>
            </div>
        </div>
    )
}

