import { Box, Button , ButtonGroup, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, IconButton, Image, Text, useToast  } from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useStoreContext } from "../../Context/StoreContext";
import { getAllCategory } from "../../services/category.service";
import { STORE_ACTION_TYPES } from "../../utils/constants";


const QuickViewDrawer = ({isOpen,onOpen,onClose}) => {

    const { selectedProduct,productList,cartItems,dispatch } = useStoreContext();
    const cartToast = useToast()

    const [quantity,setQuantity] = useState(1)
    const [filteredVariant,setFilteredVariant] = useState({})
    const [variants,setVariants] = useState({})
    const [categoryName,setCategoryName] = useState('')

    const onVariantsClick = (e) => {
        setVariants(prev => ({
            ...prev,
            [e.target.id]:e.target.value
        }))
    }
    
    useEffect(()=>{
        findVariant(variants)
    },[variants])

    useEffect(()=>{
        getAllCategories()
    },[selectedProduct])


    const getAllCategories = async() => {
        const getCategories = await getAllCategory()
        const categorylist = getCategories.docs.map(doc => ({...doc.data(),id:doc.id}))
        const findCategory = categorylist.find(category => category?.id === selectedProduct?.categoryID)
        setCategoryName(findCategory?.categoryName)
    }

    const findVariant = (variants) => {
        if(selectedProduct?.variantLists?.length === Object.keys(variants)?.length){
            let filtered = selectedProduct?.variants;
    
            for (const key in variants) {
                filtered = filtered.filter(variant => variant.variantDetails[key] === variants[key])
            }
            setFilteredVariant(filtered?.[0])
        }
    }

    const onQuantityPlus = () => {
        setQuantity(prev => prev+1)
    }

    const onQuantityMinus = () => {
        setQuantity(prev => {
            if(prev-1 === 0){
                return 1
            }else {
                return prev-1
            }
        })
    }

    
    const onCartClick = (prodID) => {
        if(selectedProduct?.variantLists.length !== Object.keys(variants).length){
            cartToast({
                title: 'Select Variants',
                status: 'warning',
                position:'top-right',
                duration: 300,
                isClosable: true,
            })
            return
        }
        if(quantity > filteredVariant?.quantity) {
            cartToast({
                title: 'Variant Maxed Out',
                status: 'error',
                position:'top-right',
                duration: 300,
                isClosable: true,
            })
            return
        }
        const findProduct = productList.find(prod => prod.id === prodID)
        const finalProduct = {...findProduct,quantity:quantity,selectedVariant:filteredVariant}
        const findInCart = cartItems.findIndex(item => item?.selectedVariant?.id === finalProduct?.selectedVariant?.id)
        setVariants({})
        setQuantity(1)
        if (findInCart === -1){
            dispatch({type:STORE_ACTION_TYPES.ADD_ONE_TO_CART,payload:finalProduct})
            cartToast({
                title: 'Added to Cart.',
                status: 'success',
                position:'top-right',
                duration: 300,
                isClosable: true,
            })
        }else{
            cartToast({
                title: 'Already in cart.',
                status: 'error',
                position:'top-right',
                duration: 300,
                isClosable: true,
            })
        }
    }

    const onCloseClick = () => {
        setVariants({})
        setFilteredVariant({})
        onClose()
    }

    
    return (
        <>
                <Drawer
                    isOpen={isOpen}
                    placement='right'
                    onClose={onCloseClick}
                    size={['full','md']}
                >
                    <DrawerOverlay />
                    <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Quick View</DrawerHeader>

                    <DrawerBody>
                        <Flex>
                            <Image src={selectedProduct?.images?.[0]} objectFit={'contain'} width={'250px'} />
                            <Box>
                                <Text my="10">{selectedProduct?.Name}</Text>
                                <Text my="10">{categoryName}</Text>
                                <Text my="10">&#8377; {filteredVariant?.variantPrice == undefined ? selectedProduct?.prices?.[0]?.RUPEE?.price : filteredVariant?.variantPrice}</Text>
                            </Box>
                        </Flex>
                            <Text my="10">{selectedProduct?.description}</Text>

                        <Flex flexDirection={'column'} my="10px">
                            {selectedProduct?.variantLists?.map(variant => (
                                <Box>
                                    <Text fontSize={'2xl'} fontWeight="bold" textTransform={'uppercase'}>{variant?.variantName}</Text>
                                    <Flex gap={'10px'}>
                                        {variant?.variantValues?.map(value => (
                                            <Button value={value} id={variant?.variantName}  onClick={(e)=>onVariantsClick(e)} bg={variants[variant?.variantName] === value ? filteredVariant?.quantity === 0 ? 'blackAlpha.200':'blackAlpha.600':''}>{value}</Button>
                                        ))}
                                    </Flex>
                                </Box>
                                
                            ))}
                        </Flex>

                        <ButtonGroup my="10px" alignItems={'center'}>
                            <IconButton icon={<AiOutlinePlus />} onClick={onQuantityPlus} />
                            <Text>quantity</Text>
                            <IconButton icon={<AiOutlineMinus />} onClick={onQuantityMinus} />
                            <Text>{quantity}</Text>
                        </ButtonGroup>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button disabled={ filteredVariant?.quantity === 0 || selectedProduct?.variants?.every(variant => variant?.quantity === 0)}  colorScheme='blue' width={'full'} onClick={()=>onCartClick(selectedProduct?.id)}>Add to Cart</Button>
                    </DrawerFooter>
                    </DrawerContent>
                </Drawer>
          </>
    )
}

export default QuickViewDrawer;
