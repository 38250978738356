import { Route, Routes } from "react-router-dom"
import AdminScreen from "./components/Admin/AdminScreen"
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import ProtectedPage from "./components/Auth/ProtectedPage"
import SignIn from "./components/Auth/SignIn"
import SignUp from "./components/Auth/SignUp"
import AddProduct from "./components/Products/AddProduct"
import Cart from "./components/StoreUI/Cart"
import CategoryUI from "./components/StoreUI/CategoryUI"
import Checkout from "./components/StoreUI/Checkout"
import Favourites from "./components/StoreUI/Favourites"
import ProductDetailPage from "./components/StoreUI/ProductDetailPage"
import Navbar, { Header } from "./components/UI/Navbar"
import StarRating from "./components/UI/StarRating"
import { AuthProvider } from "./Context/AuthContext"
import StoreProvider from "./Context/StoreContext"
import HomePage from "./pages/Store/HomePage"
import ProtectedRoute from "./utils/ProtectedRoute"
import ManageCategories from "./components/Admin/ManageCategories"
import EditCarousal from "./components/Admin/EditCarousal"
import CarousalImageCropper from "./components/Admin/CarousalImageCropper"
import EditProduct from "./components/Admin/EditProduct"
import Footer from "./components/UI/Footer"
import Pages from "./components/Admin/Pages"
import AboutUs from "./components/UI/AboutUs"
import TermsAndCondition from "./components/UI/TermsAndCondition"

const App = () => {

  return (
    <>
    <AuthProvider>
      <StoreProvider>
      <Header />
      <div className="page-container">

      <Routes>
            <Route path="/" element={<HomePage />} />
            <Route element={<ProtectedRoute/>}>
              <Route path="/protected" element={<ProtectedPage />} />
              <Route path="/addproduct" element={<AddProduct/>} />
              <Route path="/favourites" element={<Favourites/>} />
              <Route path="/checkout" element={<Checkout />} />
            </Route>
            <Route path="/category/:categoryslug" element={<CategoryUI />} />
            <Route path="/productDetail/:prodID" element={<ProductDetailPage />} />
            <Route path="/adminscreen" element={<AdminScreen />} />
            <Route path="/adminscreen/managecategory" element={<ManageCategories />} />
            <Route path="/adminscreen/editcarousal" element={<EditCarousal/>} />
            <Route path="/adminscreen/editproduct/:productID" element={<EditProduct />} />
            <Route path="/adminscreen/pages" element={<Pages />} />
            <Route path="/carousalcropimage" element={<CarousalImageCropper />} />
            <Route path="/adminscreen/editproduct/:productID" element={<EditProduct />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/about-us" element={<AboutUs/>} />
            <Route path="/terms-and-condition" element={<TermsAndCondition />} />
      </Routes>
      </div>
      <Footer />
      </StoreProvider>
    </AuthProvider>
    </>
  )
}


export default App
