import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    doc,
    where,
    query,
    orderBy,
    limit
} from 'firebase/firestore'
import { db } from './Firebase'


const storeDataCollectionRef = collection(db,'storeMetaData')

export const addStoreData = newData => {
    return addDoc(storeDataCollectionRef,newData)
}

export const updateStoreData = (id,updatedStoreData) => {
    const storeDataDoc = doc(db,"storeMetaData")
    return updateDoc(storeDataDoc,updatedStoreData)
}

export const getAllStoreData = () => {
    return getDocs(storeDataCollectionRef)
}