import { Box, Text, HStack, Flex, VStack, Link } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Link as routeLink } from 'react-router-dom'
import { getAllFooter } from "../../services/footer.service"
import { getTenCategory } from "../../services/category.service"
import { getAllStoreData } from "../../services/store.service"

const Footer = ()  => {

    const [getDatas,setGetDatas] = useState({})
    const [categoriesData,setCategoriesData] = useState([])

    useEffect(()=>{
        getAllDatas()
        getAllCategories()
    },[])

    async function getAllCategories() {
        const getCategories = await getTenCategory()
        const categorylist = getCategories.docs.map(doc => ({...doc.data(),id:doc.id}))
        setCategoriesData(categorylist.slice(0,10))
    }


    async function getAllDatas(){
        const wholeDatas = await getAllFooter()
        const datas = wholeDatas.docs.map(doc => ({...doc.data(),id:doc.id}))
        setGetDatas(datas[datas.length-1])
    }

    return (
        <div className="footer">      
        <Box bg={'#EFE5D7'}>
        <Flex mx={["10px","160px"]}  justifyContent="space-between" >
            <Box mt={["10px","70px"]} mb={["10px","70px"]}>
                <Text mb="20px" fontSize={'2xl'}>Shop</Text>
                <Box display={'flex'} flexDirection="column" gap="10px">
                    {categoriesData?.map(category => ( category?.active &&
                        <Link as={routeLink} textAlign={'left'} to={`/category/${category?.categorySlug}`}>{category?.categoryName}</Link>
                    ))}
                </Box>
            </Box>
            <Box mt={["10px","70px"]}>
                <Text mb="20px" fontSize={'2xl'}>About</Text>
                <Box display={'flex'} flexDirection="column" gap="10px">
                    <Link as={routeLink} textAlign={'left'} to="/about-us">About us</Link>
                </Box>
            </Box>
            <Box mt={["10px","70px"]}>
                <Text mb="20px" fontSize={'2xl'}>Help</Text>
                <Box display={'flex'} flexDirection="column" gap="10px">
                    <Link as={routeLink} textAlign={'left'} to="terms-and-condition">Terms & Conditions</Link>
                </Box>
            </Box>
            <Box display={'flex'} flexDirection="column" gap="10px" mt={["10px","70px"]}>
                <Box mb="20px">
                    &copy; 2023 {getDatas?.storeName ? getDatas?.storeName:'My Store'}
                </Box>
                <Text>All rights reserved</Text>
                <Text>Contact us</Text>
                <a href={`mailto:${getDatas?.contactUsEmail}`}>{getDatas?.contactUsEmail}</a>.
            </Box>
        </Flex>
     </Box>
        </div>
    )
}

export default Footer