import ProductList from "../../components/StoreUI/ProductList"

const HomePage = () => {

    return (
        <>
            <ProductList />
        </>
    )
}

export default HomePage