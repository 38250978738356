import { Box, Button, Flex, FormControl,Checkbox , HStack, Image, Input, Radio, Spacer, Text, Textarea, useDisclosure, useToast, VStack } from "@chakra-ui/react"
import { deleteObject, getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage"
import { useEffect, useState } from "react"
import { storage } from "../../services/Firebase"
import Sidebar from "../UI/Sidebar"
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { getAllCategory, addCategory } from "../../services/category.service"
import ImageSelector from "./ImageSelector"
import AddCategoryModal from "./AddCategoryModal"
import { v4} from 'uuid'
import  { ActivateCategoryModal, DeActivateCategoryModal } from "./DeleteCategoryModal"
import { getBasedOnCondition } from "../../services/products.service"
import { serverTimestamp } from "firebase/firestore"


const ManageCategories = () => {

    const [categoryDetails,setCategoryDetails] = useState({
        categoryName:'',
        description:'',
        active:false
    })
    const [categoriesData,setCategoriesData] = useState([])
    const [imageLink,setImageLink] = useState('')
    const [croppedImage,setCroppedImage] = useState('')
    const [selectedID,setSelectedID] = useState('')
    const [activateSelectedID,setActivateSelectedID] = useState('')
    const addCategoryModalDisclosure = useDisclosure()
    const deleteCategoryModalDisclosure = useDisclosure()
    const activateCategoryModalDisclosure = useDisclosure()
    const addCategoryToast = useToast()

    useEffect(()=>{
        getAllCategories()
    },[])

    const getAllCategories = async() => {
        const getCategories = await getAllCategory()
        const categorylist = getCategories.docs.map(doc => ({...doc.data(),id:doc.id}))
        setCategoriesData(categorylist)
    }

    const handleImageUpload = async(croppedImage) => {
        const storageref = ref(storage,'category/'+croppedImage?.url);

        await uploadBytes(storageref,croppedImage?.file)
        const url = await getDownloadURL(storageref)
        setImageLink(url)
        setCroppedImage('')
    }


    const deleteImage = (filename) => {
        const imageRef = ref(storage,filename);

        // Delete the file
        deleteObject(imageRef).then(() => {
            setImageLink('')
        }).catch((error) => {
            console.log(error.message)
        });
    }

    const onSubmitCategory = async() => {
        if(categoryDetails?.categoryName !== '' && imageLink !== ''){
            const newCategory = {categoryName:categoryDetails?.categoryName,description:categoryDetails?.description,categoryImage:imageLink,categorySlug:categoryDetails?.categoryName.split(' ').join('-').toLowerCase(),active:categoryDetails?.active,createdAt:serverTimestamp()}
           await addCategory(newCategory)

        setCategoryDetails({categoryName:'',description:'',active:false})
        setImageLink('')    
        addCategoryModalDisclosure.onClose()
        getAllCategories()
        }else{
            addCategoryToast({
                title: 'Fill in all Details',
                position:'top-right',
                status: 'error',
                duration: 300,
                isClosable: true,
              })
        }
    }
    
    const oncategoryDetailsChange = (e) => {
        setCategoryDetails(prev => ({
            ...prev,
            [e.target.id]:e.target.value
        }))
    }

    return (
        <Sidebar>
            <HStack my="15px" mx="50px">
            <Text fontSize={'2xl'} >Manage Categories</Text>
            <Spacer />
                <Button colorScheme={'orange'} onClick={addCategoryModalDisclosure.onOpen}>Add Category</Button>
            </HStack>
            <Text>Active Categories</Text>
            <Box mx="50px" mt="50px" display={'flex'} flexWrap="wrap" gap="25px">
                {categoriesData?.map((category,id) => (
                    category?.active && <div className="img-container">
                    <AiFillCloseCircle className="close-icon" color="red" onClick={()=>{setSelectedID(category?.id);deleteCategoryModalDisclosure.onOpen()}} />
                    <Image src={category.categoryImage} objectFit="cover" width="200px" height="200px"  />    
                    <Text textAlign={'center'} fontSize={'lg'} >{category.categoryName}</Text>
            </div>
                ))}
            </Box>
            <Text>InActive Categories</Text>
            <Box mx="50px" mt="50px" display={'flex'} flexWrap="wrap" gap="25px">
                {categoriesData?.map((category,id) => (
                    !category?.active && <div className="img-container">
                        <AiFillCheckCircle className="close-icon" color="green" onClick={()=>{setActivateSelectedID(category?.id);activateCategoryModalDisclosure.onOpen()}} />
                        <Image src={category.categoryImage} objectFit="cover" width="200px" height="200px"  />    
                        <Text textAlign={'center'} fontSize={'lg'} >{category.categoryName}</Text>
                </div>
                ))}
            </Box>
            <AddCategoryModal isOpen={addCategoryModalDisclosure.isOpen} onClose={addCategoryModalDisclosure.onClose} onOpen={addCategoryModalDisclosure.onOpen} deleteImage={deleteImage} imageLink={imageLink} onSubmitCategory={onSubmitCategory}>
                <ImageSelector setCroppedImage={setCroppedImage} croppedImage={croppedImage} handleImageUpload={handleImageUpload} />
                {imageLink !== '' && <div className="img-container category-image-display">
                    <Image my={["10px","30px"]} src={imageLink} width="100%" />
                    <AiFillCloseCircle className="close-icon"  onClick={()=>deleteImage(imageLink)} />
                </div>}
                <FormControl  id="categoryName">
                    <Input type={'text'} id="categoryName" value={categoryDetails.categoryName} placeholder="Category Name" onChange={e => oncategoryDetailsChange(e)} />
                </FormControl>
                <Checkbox my={["10px","30px"]} colorScheme='green' id="active" onChange={oncategoryDetailsChange} value={!categoryDetails?.active}>
                    {categoryDetails?.active ? 'active':'deactivate'}
                </Checkbox>
                <Textarea   id="description" value={categoryDetails.description} placeholder="Description" onChange={e => oncategoryDetailsChange(e)} />
            </AddCategoryModal>
            <DeActivateCategoryModal categoriesData={categoriesData} isOpen={deleteCategoryModalDisclosure?.isOpen} onClose={deleteCategoryModalDisclosure?.onClose} selectedID={selectedID} setSelectedID={setSelectedID} loadCategories={getAllCategories} />
            <ActivateCategoryModal categoriesData={categoriesData} isOpen={activateCategoryModalDisclosure?.isOpen} onClose={activateCategoryModalDisclosure?.onClose} loadCategories={getAllCategories} selectedID={activateSelectedID} setSelectedID={setActivateSelectedID} />
        </Sidebar>
    )
}

export default ManageCategories
