import { Box, Button, Flex, Grid, IconButton, Image, Text } from "@chakra-ui/react";
import { useState } from "react"
import { BsHeart } from "react-icons/bs";
import { useStoreContext } from "../../Context/StoreContext"
import { STORE_ACTION_TYPES } from "../../utils/constants";
import { useNavigate} from 'react-router-dom'
import { AiOutlineDelete,AiOutlinePlusCircle,AiOutlineMinusCircle,AiFillHeart } from 'react-icons/ai'
import { useEffect } from "react";
import { useAuth } from "../../Context/AuthContext";

const Cart = () => {

    const [cartTotal,setCartTotal] = useState()
    const {user} = useAuth()
    const { cartItems,dispatch,totalPriceFromCart } = useStoreContext()
    const navigate = useNavigate()

    const onDeleteClick = (prodID) => {
        dispatch({type:STORE_ACTION_TYPES.DELETE_ONE_FROM_CART,payload:prodID})
    }

    const onQuantityPlusClick = (prodID) => {
        dispatch({type:STORE_ACTION_TYPES.INCREASE_ONE_TO_QUANTITY,payload:prodID})
    }

    const  onQuantityMinusClick = (prodID) => {
        dispatch({type:STORE_ACTION_TYPES.DECREASE_ONE_TO_QUANTITY,payload:prodID})
    }

    const onFavouriteClick = (prodID) => {
        dispatch({type:STORE_ACTION_TYPES.ADD_ONE_TO_FAVOURITE,payload:{ID:prodID,src:'cart'}})
    }

    const onBuyClick = () => {
        if(user === null){
            navigate('/signin')
            localStorage.setItem('isFromCart',true)
            dispatch({type:STORE_ACTION_TYPES.PRODUCTS_TO_BUY_FROM_CART,payload:cartItems})
            dispatch({type:STORE_ACTION_TYPES.TOTAL_PRICE_fROM_CART,payload:cartTotal})
            return
        } 
        dispatch({type:STORE_ACTION_TYPES.PRODUCTS_TO_BUY_FROM_CART,payload:cartItems})
        navigate('/checkout')
    }

    console.log('cartItems =>',cartItems);

    useEffect(()=>{
        setCartTotal(
            parseFloat(cartItems.reduce((a,b)=> a + Number(b?.selectedVariant?.variantPrice) * b.quantity, 0)).toFixed(2)
        )
    },[cartItems])


    return (
        <>
            <Text textAlign={'center'} textTransform="uppercase" fontSize={'2xl'} fontWeight="bold">Cart</Text>
            <Flex direction={'column'} width={['95%','75%']} margin="auto" gap="20px">
                {cartItems.map(cart => (
                    <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
                        <IconButton icon={cart?.favourite ? <AiFillHeart color={'red'} />:<BsHeart /> } onClick={()=>onFavouriteClick(cart.id)} />
                        <Image src={cart?.selectedVariant?.images?.[0]} alt="product" width="50px" objectFit={'contain'} />
                        <Text>{cart.Name}</Text>
                        <Text>{cart?.selectedVariant?.variantTitle}</Text>
                        <Box display={'flex'} justifyContent='space-between' alignItems='center'>
                            <IconButton icon={<AiOutlinePlusCircle />} onClick={()=>onQuantityPlusClick(cart?.selectedVariant?.id)} />
                            <Text>{cart.quantity}</Text>
                            <IconButton icon={<AiOutlineMinusCircle />} onClick={()=>onQuantityMinusClick(cart?.selectedVariant?.id)} />
                        </Box>
                        <Text>&#8377;  {cart?.selectedVariant?.variantPrice * cart?.quantity}</Text>
                        <AiOutlineDelete onClick={()=>onDeleteClick(cart?.selectedVariant?.id)} />
                    </Box>
                ))}
                <Flex justifyContent={'space-between'}>
                    <Text>Total</Text>
                    <Text>&#8360; {cartTotal}</Text>
                </Flex>
                <Flex justifyContent={'flex-end'} gap="10px">
                    <Button onClick={()=>onBuyClick()}>Buy Now</Button>
                    <Button>Cancel</Button>
                </Flex>
            </Flex>
        </>
    )
}

export default Cart