import { Box, Button, Flex, FormControl, FormLabel, IconButton, Image, Input, Radio, RadioGroup, Stack, Text, Textarea, useDisclosure, useToast, VStack } from "@chakra-ui/react"
import { useEffect } from "react"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import { useState } from "react"
import { useStoreContext } from "../../Context/StoreContext"
import { AddCreditCard as nocard , AtmCard, CreateCreditCard, CreditCard } from "../UI/CreditCard"
import {IoIosAddCircle, IoMdAddCircleOutline} from 'react-icons/io'
import { STORE_ACTION_TYPES } from "../../utils/constants"
import { addUserProfile , updateUserProfile , getAllUserProfile,deleteUserProfile } from "../../services/user.profile"
import { serverTimestamp } from "firebase/firestore"



const Checkout = () => {

    const [paymentOption,setPaymentOption] = useState('')
    const [totalPrice,setTotalPrice] = useState(0)
    const [metaDatas,setMetaDatas] = useState([])
    const [updateAddressID,setUpdateAddressID] = useState('')
    const {isOpen,onOpen,onClose} = useDisclosure()
    const CreditCardDisclosure = useDisclosure()
    const updateAddressModalDisclosure = useDisclosure()
    const DeleteAddressModalDisclosure = useDisclosure()
    const { cartItems,totalPriceFromCart,buyingProductFromCart,allCreditCards,allPersonalDetails } = useStoreContext()

    useEffect(()=>{
        setTotalPrice(
            parseFloat(buyingProductFromCart.reduce((a,b)=> a + Number(b?.selectedVariant?.variantPrice) * b.quantity, 0)).toFixed(2)
        )
    },[])

    const getUserProfile = async() => {
        const getList = await getAllUserProfile()
        const allUserProfile = getList.docs.map(doc => ({...doc.data(),id:doc.id}))
        setMetaDatas(allUserProfile)
    }

    useEffect(()=>{
        getUserProfile()
    },[])

    console.log(metaDatas);

    return (
        <Flex 
            width={['100%','100%','85%']}
            flexDirection={['column','colums','row']}
            m="auto"
            sx={
                { 
                '::-webkit-scrollbar':{
                    display:'none'
                }
            }
        } gap={'10px'} py="10px">
            <Box 
                // flex={['','1']}
                backgroundColor={"blackAlpha.100"}
                borderRadius={'2xl'}
                width={['100%',"100%","70%"]}
                overflowY={'auto'}
                sx={
                    { 
                '::-webkit-scrollbar':{
                        display:'none'
                    }
                }}
                px={'5'}
            > {/** right side */}
                <Flex direction={'column'} my="10px"> {/** address displaying */}
                    <Text fontSize={'2xl'} fontWeight="bold">Delivery address</Text>
                    <Flex 
                        gap="10px"
                        overflow={'auto'}
                        sx={
                            { 
                        '::-webkit-scrollbar':{
                                display:'none'
                            }
                        }}
                    >
                        {metaDatas?.map(addr => (
                            <Box
                                width="250px"
                                height="170px"
                                padding='5'
                                overflow={'auto'}
                                background='gray.400'
                                sx={
                                    { 
                                '::-webkit-scrollbar':{
                                        display:'none'
                                    }
                                }
                            }
                            >
                                <Text>{addr?.name}</Text>
                                <Text>{addr?.address}</Text>
                                <Text>{addr?.phoneNumber}</Text>
                                <VStack alignItems={'flex-start'}>
                                    <Button variant={'link'} onClick={()=>{setUpdateAddressID(addr?.id);updateAddressModalDisclosure?.onOpen()}}>Update Address Detail</Button>
                                    <Button variant={'link'} onClick={()=>{setUpdateAddressID(addr?.id);DeleteAddressModalDisclosure?.onOpen()}}>Delete Address Detail</Button>
                                </VStack>
                            </Box>
                        ))}
                        <Box
                            minWidth="150px"
                            display={'flex'}
                            justifyContent="center"
                            alignItems={'center'}
                            height="170px"
                            py="5"
                            px="2"
                            overflow={'auto'}
                            background='gray.400'
                            sx={
                                { 
                            '::-webkit-scrollbar':{
                                    display:'none'
                                }
                            }
                            }
                            >
                                <IconButton icon={<IoIosAddCircle/>} onClick={onOpen} >Add Address</IconButton>
                            </Box>
                    </Flex>
                </Flex>
                <Box my="10px">
                    <Text fontSize={'2xl'} fontWeight="bold">Order Summary</Text>
                       <Flex direction={'column'} gap="10px">
                        {buyingProductFromCart.map(currentProduct => (
                            <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
                                {/* <IconButton icon={currentProduct?.favourite ? <AiFillHeart color={'red'} />:<BsHeart /> } onClick={()=>onFavouriteClick(currentProduct.id)} /> */}
                                <Image src={currentProduct?.selectedVariant?.images?.[0]} alt="product" width="50px" objectFit={'contain'} />
                                <Text>{currentProduct?.Name}</Text>
                                <Text>{currentProduct?.selectedVariant?.variantTitle}</Text>
                                <Box display={'flex'} justifyContent='space-between' alignItems='center'>
                                    
                                    <Text>{currentProduct.quantity}</Text>
                                    
                                </Box>
                                <Text>&#8377; {parseFloat(currentProduct?.selectedVariant?.variantPrice * currentProduct?.quantity)}</Text>
                                
                            </Box>
                        ))}
                        
                       </Flex>
                    {/* product display in here */}
                </Box>
                <Box my="10px" sx={
                            { 
                        '::-webkit-scrollbar':{
                                display:'none'
                            }
                        }}>
                    <Text fontSize={'2xl'} fontWeight="bold">Payment Details</Text>
                    <RadioGroup 
                        onChange={setPaymentOption}
                        value={paymentOption}>
                        <Stack
                             direction='row'
                             sx={
                                { 
                            '::-webkit-scrollbar':{
                                    display:'none'
                                }
                            }}
                        >
                            <Radio value='1'>Credit / Debit Card</Radio>
                            <Radio value='2'>Net Banking</Radio>
                            <Radio value='3'>Cash on delivery</Radio>
                            <Radio value='4'>UPI</Radio>
                            <Radio value='5'>EMI</Radio>
                        </Stack>
                    </RadioGroup>
                        {/* displaying details based on  radio button selection */}
                    <Flex 
                        gap="20px"
                        overflow={'auto'}
                        sx={
                            { 
                        '::-webkit-scrollbar':{
                                display:'none'
                            }
                        }}
                    >
                        {allCreditCards.map(item => (
                            // <CreditCard />
                            <AtmCard cardData={item} />
                        ))}
                        <AddCreditCard onOpen={CreditCardDisclosure.onOpen} />
                    </Flex>
                   
                </Box>
            </Box>
            <Box width={['100%','100%','30%']}> {/** left side */}
                <Box backgroundColor={"blackAlpha.100"} width={['100%','100%','auto']} borderRadius="2xl" py="4" px="9">
                    <Text py="3">Order Details</Text>
                    <hr /> 
                    <Flex py="3" justifyContent={'space-between'}>
                        <Text>Price</Text>
                        <Text>{totalPrice}</Text>
                    </Flex>
                    <Flex py="3" justifyContent={'space-between'}>
                        <Text>Delivery Charges</Text>
                        <Text>0</Text>
                    </Flex>
                    <Flex py="3" justifyContent={'space-between'}>
                        <Text>Discount Price</Text>
                        <Text>0</Text>
                    </Flex>
                    <hr />
                    <Flex py="3" justifyContent={'space-between'}>
                        <Text>Total Amount</Text>
                        <Text>{totalPrice}</Text>
                    </Flex>
                    <Box p="10px" backgroundColor={'green.400'}>
                        <Text>You have saved a total amount of rupees</Text>
                    </Box>
                </Box>
            </Box>
            <AddressModal isOpen={isOpen}  onClose={onClose} getUserProfiles={getUserProfile} />
            <UpdateAddressModal isOpen={updateAddressModalDisclosure?.isOpen} onClose={updateAddressModalDisclosure?.onClose} allUserProfiles={metaDatas} updateAddressID={updateAddressID} setUpdateAddressID={setUpdateAddressID} getUserProfiles={getUserProfile} />
            <CreditDetailModal isOpen={CreditCardDisclosure.isOpen} onClose={CreditCardDisclosure.onClose} />
            <DeleteAddressModal isOpen={DeleteAddressModalDisclosure?.isOpen} onClose={DeleteAddressModalDisclosure?.onClose} getUserProfiles={getUserProfile} updateAddressID={updateAddressID} setUpdateAddressID={setUpdateAddressID} />
        </Flex>
    )
}

export default Checkout



const AddressModal = ({isOpen,onClose,getUserProfiles}) => {

    const {dispatch} = useStoreContext()
    const toast = useToast()

    const [addressDetail,setAddressDetail] = useState({
        name:'',
        address:'',
        phoneNumber:''
    })

    const onDetailChnage = (e) => {
        setAddressDetail(prev => ({
            ...prev,
            [e.target.id]:e.target.value
        }))
    }


    const onHandleSubmit = async() => {
        if(addressDetail?.name !== '' && addressDetail?.address !== '' && addressDetail?.phoneNumber !== ''){
            const details = {...addressDetail,createdAt:serverTimestamp()}
            await addUserProfile(details)
            getUserProfiles()
            onClose()
        }else{
            toast({
                title: 'fill in all details',
                position:'top-right',
                status: 'error',
                duration: 300,
                isClosable: true,
            })
        }
    }

  return (
    <>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Address Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input  type={'text'} placeholder="name" id="name" value={addressDetail?.name} onChange={e => onDetailChnage(e)} />
            <Textarea my={'7'} placeholder="enter the address here" id="address" value={addressDetail?.address} onChange={e => onDetailChnage(e)} />
            <Input type="text" placeholder="mobile no" id="phoneNumber" value={addressDetail?.phoneNumber} onChange={e => onDetailChnage(e)} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost' onClick={onHandleSubmit}>Add Address</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const UpdateAddressModal = ({isOpen,onClose,allUserProfiles,updateAddressID,setUpdateAddressID,getUserProfiles}) => {

    const [addressDetail,setAddressDetail] = useState({})

    console.log(updateAddressID);
    useEffect(()=>{
        getAddressDetail()
    },[updateAddressID])

    const getAddressDetail = () => {
        const findAddress = allUserProfiles?.find(profile => profile?.id === updateAddressID)
        setAddressDetail(findAddress)
    }

    console.log(addressDetail);

    const onCloseClick = () => {
        setAddressDetail({})
        setUpdateAddressID('')
        onClose()
    }

    const onDetailChnage = (e) => {
        setAddressDetail(prev => ({
            ...prev,
            [e.target.id]:e.target.value
        }))
    }

    const onHandleSubmit = async() => {
        await updateUserProfile(addressDetail?.id,addressDetail)
        setUpdateAddressID('')
        getUserProfiles()
        onClose()
    }

    return (
        <>
      <Modal isOpen={isOpen} onClose={onCloseClick}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Address Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input  type={'text'} placeholder="name" id="name" value={addressDetail?.name} onChange={e => onDetailChnage(e)} />
            <Textarea my={'7'} placeholder="enter the address here" id="address" value={addressDetail?.address} onChange={e => onDetailChnage(e)} />
            <Input type="text" placeholder="mobile no" id="phoneNumber" value={addressDetail?.phoneNumber} onChange={e => onDetailChnage(e)} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onCloseClick}>
              Close
            </Button>
            <Button variant='ghost' onClick={onHandleSubmit}>Update Address</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
    )
}

const DeleteAddressModal = ({isOpen,onClose,updateAddressID,setUpdateAddressID,getUserProfiles}) => {

    const onCloseClick = () => {
        setUpdateAddressID('')
        onClose()
    }

    const onHandleDelete = async() => {
        await deleteUserProfile(updateAddressID)
        setUpdateAddressID('')
        getUserProfiles()
        onClose()
    }

    return (
        <Modal isOpen={isOpen} onClose={onCloseClick}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Address Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you'd like to delete the address?</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onCloseClick}>
              Close
            </Button>
            <Button variant='ghost' onClick={onHandleDelete}>Update Address</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}

const CreditDetailModal = ({isOpen,onClose}) => {

    const { dispatch } = useStoreContext()

    const [cardDetails,setCardDetails] = useState({
        name:'',
        cardNumber:'',
        validThru:'',
        cvv:''
    })

    const onDetailChnage = (e) => {

        setCardDetails(prev => ({
            ...prev,
            [e.target.id]:e.target.value
        }))
    }

    const onCreditSubmit = () => {
        dispatch({type:STORE_ACTION_TYPES.ADD_CREDIT_CARD,payload:cardDetails})
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Card Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
                <FormLabel>Card Number</FormLabel>
                <Input type='text' id="cardNumber" value={cardDetails.cardNumber} onChange={(e)=>onDetailChnage(e)}   />
            </FormControl>
            <FormControl>
                <FormLabel>Name on Card</FormLabel>
                <Input type='text' id="name" value={cardDetails.name} onChange={(e)=>onDetailChnage(e)} />
            </FormControl>
            <FormControl>
                <FormLabel>Valid thru</FormLabel>
                <Input type='text' id="validThru" value={cardDetails.validThru} onChange={(e)=>onDetailChnage(e)} />
            </FormControl>
            <FormControl>
                <FormLabel>CVV</FormLabel>
                <Input type='number' id="cvv" value={cardDetails.cvv} onChange={(e)=>onDetailChnage(e)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost' onClick={onCreditSubmit}>Add credit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}


export const AddCreditCard = ({onOpen}) => {

    return (
        <div className="atm-card atm-card-create">
            <IoMdAddCircleOutline style={{cursor:'pointer'}} fontSize={'60px'} onClick={onOpen} />
        </div>
    )
}

