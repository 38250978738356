import { Box, Flex,  Grid,  Image,  Link,  Select, Text, useDisclosure} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Link as RouteLink, useNavigate} from 'react-router-dom'
import DetailModal from "../UI/DetailModal"
import { useStoreContext } from "../../Context/StoreContext"
import { STORE_ACTION_TYPES } from "../../utils/constants"
import ProductDetail from "../UI/ProductDetail"
import QuickViewDrawer from "./QuickViewDrawer"
import MainCarousal from "./MainCarousal"
import { getAllCategory } from "../../services/category.service"
import { getAllProducts } from "../../services/products.service"

const ProductList = () => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate = useNavigate()
    const {productList,dispatch} = useStoreContext()
    const quickDrawerDisclosure = useDisclosure()
    const [filterLinks,setFilterLinks] = useState([])
    useEffect(()=>{
        (async function(params) {
            const getProducts = await getAllProducts()
            dispatch({type:STORE_ACTION_TYPES.ADD_ALL_PRODUCTS,payload:getProducts.docs.map(doc => ({...doc.data(),id:doc.id,favourite:false}))})
        })()
    },[])

    useEffect(()=>{
        getAllCategories()
    },[])

    const getAllCategories = async() => {
        const getCategories = await getAllCategory()
        const categorylist = getCategories.docs.map(doc => ({...doc.data(),id:doc.id}))
        setFilterLinks(categorylist)
    }
    const handleFilter = e => {
        navigate(`/${e.target.value}`)
    }

    return (
        <Box maxW={['100%','100%']}   display={'flex'} gap="10px" flexDirection={['column','row']} flexWrap="wrap">
                <MainCarousal />
                <Flex maxH={'30%'} justifyContent='center'   alignItems='center' m="10"  width='100%' gap={6} >
                        {filterLinks?.map(link => (
                            link?.active && 
                            <Box textAlign='center' display={['none','block']}>  
                            <Link as={RouteLink} to={`/category/${link?.categorySlug}`}>
                                <Image src={link?.categoryImage} loading="lazy" alt={link?.categoryName} display="block" width="100px" height='auto' objectFit='cover' borderRadius='full' />
                            </Link>
                            <Text marginTop='16px' fontSize='16px'>{link?.categoryName}</Text>
                            </Box>

                        ))}
                        <Select display={['block','none']} onChange={(e) => handleFilter(e)}>
                            {filterLinks?.map(link => (
                                link?.active &&
                                <option value={`/category/${link?.categorySlug}`}>{link?.categoryName}</option>
                                ))}
                        </Select>
                </Flex>
            <Grid templateColumns={['repeat(1, 1fr)','repeat(2, 1fr)','repeat(3, 1fr)','repeat(5, 1fr)']} gap={10} mx="160px" mb="100px" >    
                {productList.length > 0 && productList.map(product => (
                    product?.categoryActive && product?.status !== false &&
                    <ProductDetail quickDisclosureOpen={quickDrawerDisclosure.onOpen} product={product} onOpen={onOpen} key={product.id} />
                ))}
            </Grid>
                <DetailModal isOpen={isOpen} onClose={onClose} onOpen={onOpen}  />
                <QuickViewDrawer isOpen={quickDrawerDisclosure.isOpen} onClose={quickDrawerDisclosure.onClose} onOpen={quickDrawerDisclosure.onOpen}  />
        </Box>
    )
}

export default ProductList

