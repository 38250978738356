import {
    collection,
    getDoc,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query,
    where
} from 'firebase/firestore'
import { db } from './Firebase'

export const productsCollectionRef = collection(db,"products")

export const addProduct = newProduct =>{
    return addDoc(productsCollectionRef,newProduct)
}

export const updateProduct = (id,updatedProduct)=>{
    const productDoc = doc(db,"products",id)
    return updateDoc(productDoc,updatedProduct)
}

export const deleteProduct = (id) => {
    const productDoc = doc(db,"products",id);
    return deleteDoc(productDoc)
}

export const getAllProducts = () =>{
    return getDocs(productsCollectionRef)
}

export const getProduct = (id)=>{
    const productDoc = doc(db,"products",id);
    return getDoc(productDoc)
}


export const getBasedOnCondition = async(categoryID) => {
    const q = query(productsCollectionRef, where("categoryID", "==", categoryID));

    const querySnapshot = await getDocs(q);
    return querySnapshot
}