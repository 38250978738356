import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Image,
    Box,
} from '@chakra-ui/react'
import { BsFillCheckCircleFill } from 'react-icons/bs'

const AddVariantImages =({isOpen,onClose,allImages,setVariantID,imagesSubmit,singleVariantImages,setSingleVariantImages}) => {

  const onCloseClick = () => {
    setVariantID('')
    setSingleVariantImages([])
    onClose()
  }

  const onSelectImage = (src) => {
    setSingleVariantImages([...singleVariantImages,src])
  }
  
  const onUnSelectImage = (src) => {
    const list = singleVariantImages?.filter(item => item !== src)
    setSingleVariantImages(list)
  }

    return (
      <>
  
        <Modal isOpen={isOpen} onClose={onCloseClick} size="lg" scrollBehavior='inside'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Images</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Box display={'flex'} flexWrap="wrap" gap="5">
                    {allImages?.map(image => (
                            <span className={singleVariantImages?.find(img => img === image) ? 'selected-img-container' : 'unselected-img-container'}>
                              <BsFillCheckCircleFill className={singleVariantImages?.find(img => img === image) ? 'select-check-icon' : 'unselect-check-icon'}  onClick={()=>{singleVariantImages?.find(img => img === image) ? onUnSelectImage(image):onSelectImage(image)}} />
                              <Image src={image} width="100%" height="100%"  />  
                            </span>
                    ))}
                </Box>
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onCloseClick}>
                Cancel
              </Button>
              <Button variant='ghost' onClick={imagesSubmit}>Ok</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

export default AddVariantImages