import { Box, Button, Checkbox, HStack, IconButton, Image, Input, Select, Spacer, Stack, Table, TableContainer, Tbody, Td, Text, Textarea, Thead, Tr, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { getProduct } from "../../services/products.service";
import { useParams } from "react-router-dom"
import Sidebar from "../UI/Sidebar"
import { AiFillCloseCircle } from "react-icons/ai";
import { getAllCategory } from "../../services/category.service";
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../services/Firebase";
import { updateProduct } from "../../services/products.service";
import { BiImageAdd } from "react-icons/bi";
import AddVariantImages from "./AddVariantImages";

const EditProduct = () => {

    const { productID } = useParams()
    const [selectedProduct,setSelectedProduct] = useState({})
    const [currentCategory,setCurrentCategory] = useState({})
    const [singleVariantImages,setSingleVariantImages] = useState([])
    const [selectedVariantID,setSelectedVariantID] = useState('')
    const VariantImagesModalDisclosure = useDisclosure()
    const toast = useToast()

    useEffect(()=>{
        getCurrentProduct()
    },[])

    const getCurrentProduct = async() => {
        const product = await getProduct(productID)
        setSelectedProduct({...product.data(),id:product.id})
        getCategories(product.data())
    }

    const onSubmitProduct = async() => {
        const finalProduct = {...selectedProduct,Name:selectedProduct?.Name,description:selectedProduct?.description,images:selectedProduct?.images,prices:selectedProduct?.prices,variantLists:selectedProduct?.variantLists,variants:selectedProduct?.variants}
        await updateProduct(finalProduct?.id,finalProduct)
        toast({
            title: 'Product updated',
            position:'top-right',
            status: 'success',
            duration: 300,
            isClosable: true,
        })
        getCurrentProduct()
    }

    async function getCategories(product){
        const getCategoriesList = await getAllCategory()
        const categoriesList = getCategoriesList.docs.map(doc => ({...doc.data(),id:doc.id}))
        const findCategory = categoriesList?.find(category => category?.id === product?.categoryID)
        setCurrentCategory(findCategory)
    }

    const onSelectedProductChange = (e) => {
        //change name , description , inventory , status
        setSelectedProduct(prev => ({
            ...prev,
            [e.target.id]:e.target.value
        }))
    }

    const handleVariantsChange = (e,varID) => {
        const uniqueChange = selectedProduct?.variants?.map(prod => prod?.id === varID ? ({...prod,[e.target.id]:e.target.value}):{...prod})
        setSelectedProduct(prev => ({
            ...prev,
            variants:uniqueChange
        }))
    }

    const handlePricesChange = (e) => {
        setSelectedProduct(prev => ({
            ...prev,
            prices:[{'RUPEE':{currency:'Indian Rupee',price:e.target.value}}]
        }))
    }

    const handleImageUpload = (e) => {
        const newImgLink = e.target.files[0]
        const filename =  new Date().getTime() + newImgLink.name
        const storageref = ref(storage,filename);
        const uploadImagetask = uploadBytesResumable(storageref,newImgLink)

        uploadImagetask.on('state_changed', 
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case 'paused':
                console.log('Upload is paused');
                break;
            case 'running':
                console.log('Upload is running');
                break;
            }
        }, 
        (error) => {
            console.log(error)
        }, 
        () => {
            getDownloadURL(uploadImagetask.snapshot.ref).then((downloadURL) => {
                setSelectedProduct(prev => ({
                    ...prev,
                    images:[...prev.images,downloadURL]
                }))
            });
        }
        );
    }

    const deleteImage = (filename) => {
        const imageRef = ref(storage,filename);

        // Delete the file
        deleteObject(imageRef).then(() => {
            setSelectedProduct(prev => ({
                ...prev,
                images:prev.images.filter(data => data !== filename)
            }))
        }).catch((error) => {
            console.log(error.message)
        });
    }


    const deleteImageFromVariantTable = (prodId,imgSrc) => {
        const newList = selectedProduct?.variants?.map(prod => prod?.id === prodId ? ({...prod,images:prod?.images?.filter(img => img !== imgSrc)}): ({...prod}))
        setSelectedProduct(prev => ({
            ...prev,
            variants:newList
        }))
    }

    const onVariantImageModal = (varID) => {
        setSelectedVariantID(varID)
        const findVariant = selectedProduct?.variants?.find(prod => prod?.id === varID)
        setSingleVariantImages(findVariant?.images)
        VariantImagesModalDisclosure?.onOpen()
    }

    const singleVariantImagesSubmit = () => {
        const newList = selectedProduct?.variants?.map(prod => prod?.id === selectedVariantID ? ({...prod,images:[...singleVariantImages]}):({...prod}))

        setSelectedProduct(prev => ({
            ...prev,
            variants:newList
        }))
        setSingleVariantImages([]);
        setSelectedVariantID('')
        VariantImagesModalDisclosure?.onClose()
    }

    return (
        <Sidebar>
            <Box maxW='1000' mx={"auto"}  backgroundColor="whitesmoke" p='3'>
                <HStack>
                    <Text fontSize='2xl' fontWeight='bold'>Edit Product</Text>
                    <Spacer />
                    <Button colorScheme='teal' onClick={onSubmitProduct}>Submit Product</Button>
                </HStack>
                <Input w="350px" placeholder='Product Name' type="text" size='md' my="3" id="Name" value={selectedProduct?.Name} onChange={onSelectedProductChange} />
                <Stack direction={'row'} alignItems="center" my="3">
                    <Text>Category - {currentCategory?.categoryName}</Text>
                </Stack>
                <Textarea  id="description" placeholder="Description." value={selectedProduct?.description} onChange={onSelectedProductChange} ></Textarea> 
            </Box>
            <Box maxW='1000' mx={"auto"}  backgroundColor="whitesmoke" mt="5" p="3">
                <Text fontSize='2xl' my="3" fontWeight='bold'>Pricing</Text>
                <HStack align="center" my='3'>
                    <Text fontSize='md' my="3">Give products a price for each of the currencies that you sell in</Text>
                    <Spacer />
                </HStack>
                <HStack w="100%">
                    <Box p={5} w="30%" shadow='md' borderWidth='1px'>
                        <Text>Currency</Text>
                        <Box display={'flex'} alignItems="center" width={'100%'}>
                            <Text border={'1px'} px="8px" borderRadius={'10px'} py="8px" borderStyle="solid" width={'100%'} borderColor={'gray.300'}>{selectedProduct?.prices?.[0]?.RUPEE?.currency} &#8377;</Text>
                        </Box>
                    </Box>
                    <Box shadow='md' w="70%" borderWidth='1px' p={5}>
                        <Text>Amount</Text>
                        <Input placeholder='Enter Amount' id="price" type="number" onChange={handlePricesChange} value={selectedProduct?.prices?.[0]?.RUPEE?.price}/>
                    </Box>
                </HStack>
            </Box>
            <Box maxW='1000' mx={"auto"}  backgroundColor="whitesmoke" mt="5" p="3">
                <HStack>
                    <Text>images for the product</Text>
                    <label className="image-picker"><Input type="file" onChange={handleImageUpload} />add Images</label>

                    <Box display='flex' gap='5px'>
                        {selectedProduct?.images?.map(srcLink => (
                            <span className="img-container">
                            <AiFillCloseCircle className="close-icon" onClick={()=> deleteImage(srcLink)} />
                            <Image width="50px" height="50px" src={srcLink} />
                        </span>
                        ))}
                    </Box>
                </HStack>
                <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Td>S.no</Td>
                                <Td>Variant Details</Td> 
                                <Td>Variant Name</Td>
                                <Td>Variant Price</Td>
                                <Td>Quantity</Td>
                                <Td>Images</Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                        {selectedProduct?.variants?.map((Prod,id) => (
                                <Tr key={Prod?.id}>
                                    <Td>{id+1}</Td>
                                    <Td>{Object.keys(Prod?.variantDetails || {}).map(elem => (
                                        <span>{Prod?.variantDetails[elem]} </span>
                                    ))}</Td>
                                    <Td><Input value={Prod?.variantTitle} id="variantTitle" type="text"  onChange={e => handleVariantsChange(e,Prod?.id)}  /></Td>
                                    <Td><Input value={Prod?.variantPrice} id="variantPrice" type="number" onChange={e => handleVariantsChange(e,Prod?.id)}  /></Td>
                                    <Td><Input value={Prod?.quantity === "" ? 0 : Prod?.quantity} id="quantity" type="number" min="0" onChange={e => handleVariantsChange(e,Prod?.id)} /></Td>
                                    <Td position='relative' display={'flex'} gap="3px">
                                    <IconButton icon={<BiImageAdd />} onClick={()=>onVariantImageModal(Prod?.id)} />
                                        {Prod?.images?.map(data => (
                                        <span className="img-container">
                                            <img width='50px' height="30px" src={data} />
                                            <AiFillCloseCircle className="close-icon" onClick={e => deleteImageFromVariantTable(Prod?.id,data)} />
                                        </span>
                                    ))}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            <AddVariantImages allImages={selectedProduct?.images} isOpen={VariantImagesModalDisclosure?.isOpen} onClose={VariantImagesModalDisclosure?.onClose} imagesSubmit={singleVariantImagesSubmit} singleVariantImages={singleVariantImages} setSingleVariantImages={setSingleVariantImages} setVariantID={setSelectedVariantID} />
        </Sidebar>
    )
}

export default EditProduct
