import { Box, Button, IconButton, Text,chakra, Flex, HStack, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter, useDisclosure, VStack, Image } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { useAuth } from "../../Context/AuthContext"
import {AiOutlineShoppingCart} from 'react-icons/ai'
import { BsCart4, BsHeart, BsPerson } from "react-icons/bs"
import { useRef } from "react"
import { IoMdMenu } from 'react-icons/io'
import { useStoreContext } from "../../Context/StoreContext"
import { useState } from "react"
import { getAllStoreData } from "../../services/store.service"
import { useEffect } from "react"
import { getAllFooter } from "../../services/footer.service"



// desktop version

export const Header = () => {

    const {user,logout} = useAuth()
    const {cartItems} = useStoreContext()
    const [storeData,setStoreData] = useState({})

    const getHeaderData = async() => {
        const wholeDatas = await getAllFooter()
        const datas = wholeDatas.docs.map(doc => ({...doc.data(),id:doc.id}))
        setStoreData(datas[datas.length-1])
    }

    useEffect(()=>{
      getHeaderData()
    },[])

    return (
        <chakra.header id="header" bgColor='#fff' position={'sticky'} top="0" zIndex={'overlay'}>
          <Flex
            w="100%"
            px="6"
            py="5"
            align="center"
            justify="space-between"
          >
            <Text fontSize={'2xl'} fontWeight='bold' as={Link} to="/">{storeData?.storeLogo ? <Image src={storeData?.storeLogo} width="50px" height="50px"  />:storeData?.storeName ? storeData?.storeName : 'My Store'}</Text>
            <HStack as="nav" spacing="5">
              
              <Box display={['none','flex']} justifyContent='space-between' alignItems='center' gap={3}>
                  <IconButton icon={<BsHeart />} as={Link} to='/favourites'  />
                  <Box position={'relative'}>
                    <Text zIndex={'tooltip'} borderRadius="full" bg='red.400' position={'absolute'} py="2px" px="8px" top="-3" right="-3">{cartItems.length}</Text>
                    <Link to="/cart" ><BsCart4 size={'30px'}/></Link>
                  </Box>
                  {!user?.email && (<>
                  <Link to="/signin"><BsPerson size={'40px'} /></Link></>)}
                  {user?.email &&
                   <div class="dropdown"><BsPerson size={'40px'} />
                   <div class="dropdown-content">
                        <Link style={{width:'full',textAlign:'center'}} to='/adminscreen'>Admin</Link>
                        <Button width="full" onClick={()=>logout()}>Logout</Button>
                   </div>
                 </div>}
              </Box>
            </HStack>
              <Box display={['block','none']}>
                <MobileNavbar user={user} logout={logout} />
              </Box>
            
          </Flex>
        </chakra.header>
      );
}

// mobile version



export function MobileNavbar({
      placement = "right",
      width,
      title = "Menu",
      user,
      footer,
      logout
    }) {
        const p = 15;
        const { isOpen, onOpen, onClose } = useDisclosure();
        const btnRef = useRef();
        const {cartItems} = useStoreContext()
      return (
        <Flex w={width}>
          <Button ref={btnRef} onClick={onOpen}>
            <IoMdMenu size="26px" />
          </Button>
          <Drawer
            isOpen={isOpen}
            placement={placement}
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent alignItems="center">
              <DrawerCloseButton alignSelf="end" mx={p} my={p} />
              <DrawerHeader my={p}>
                <Text as="p"> {title} </Text>
              </DrawerHeader>
              <DrawerBody>
              <VStack as="nav" spacing="5" width={'100%'}>
                  {!user?.email && (<><Button width={'100%'} as={Link} to='/signin' mr='5px'>Sign in</Button>
                  <Button width={'100%'} as={Link} to='/signup'>Sign up</Button></>)}
                  <Button width={'100%'} as={Link} to='/addproduct'>Add Product</Button>
                  <IconButton width={'100%'} icon={<BsHeart />} as={Link} to='/favourites'  />
                  <Box position={'relative'}>
                    <Text zIndex={'tooltip'} borderRadius="full" bg='red.400' position={'absolute'} py="2px" px="8px" top="-2" right="-3">{cartItems.length}</Text>
                    <IconButton icon={<AiOutlineShoppingCart/>} as={Link} to="/cart" />
                  </Box>
                  <Text>{user?.email}</Text>
                  {user?.email && <Button width={'100%'} onClick={()=>logout()}>Logout</Button>}
            </VStack>
              </DrawerBody>
              <DrawerFooter>{footer}</DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Flex>
      );
}