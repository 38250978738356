import { Box, Button, HStack, Image, Spacer, Text, useDisclosure } from "@chakra-ui/react"
import Sidebar from "../UI/Sidebar"
import {BiImageAdd} from "react-icons/bi"
import { useEffect, useState } from "react"
import { firebase_app, storage } from "../../services/Firebase"
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { addCarousalLists, deleteCarousal, getAllCarousal, updateCarousal } from "../../services/carousal.service"
import { serverTimestamp } from "firebase/firestore"
import { AiFillCloseCircle } from "react-icons/ai"
import { FieldValue } from "firebase/firestore"
import AddCarousalImageModal from "./AddCarousalImageModal"
import { Link } from "react-router-dom"
import DeleteCarousalModal from "./DeleteCarousalModal"
const EditCarousal = () => {
    
    const [imageLinkList,setImageLinkList] = useState([])

    const [imagesData,setImagesData] = useState([])
    const addCarousalDisclosure = useDisclosure()
    const deleteCarousalDisclosure = useDisclosure()
    const [selectedID,setSelectedID] = useState('')

    useEffect(()=>{
        getImagesData()
    },[])


    async function getImagesData(){
        const getAllImages = await getAllCarousal()
        setImagesData(getAllImages.docs.map(doc => ({...doc.data(),id:doc.id})))
    }
    
    const handleImageUpload = (e) => {
        const imgList = [...e.target.files]
        console.log('imglist =>',imgList);
        for (const key of imgList) {
            onFileSubmit(key)
        }
    }
    const onFileSubmit = (fileSrc) => {
        const imgSrc = fileSrc
        const filename =  new Date().getTime() + imgSrc.name
        const storageref = ref(storage,filename);
        const uploadImagetask = uploadBytesResumable(storageref,imgSrc)

        uploadImagetask.on('state_changed', 
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case 'paused':
                console.log('Upload is paused');
                break;
            case 'running':
                console.log('Upload is running');
                break;
            }
        }, 
        (error) => {
            console.log(error)
        }, 
        () => {
            getDownloadURL(uploadImagetask.snapshot.ref).then((downloadURL) => {
                setImageLinkList(prevState => [...prevState,downloadURL])
                // handleAddImages(downloadURL,varID)
            });
        }
        );
    } 

    const deleteImage = (filename,ListID) => {
        const imageRef = ref(storage,filename);

        // Delete the file
        const findColl = imagesData.find(image => image?.id === ListID)
        deleteObject(imageRef).then(() => {
            const newCarousal = {...findColl,images:findColl?.images.filter(link => link !== filename)}
            updateCarousal(ListID,newCarousal).then((data)=>{
                getImagesData()
            })
            if(newCarousal?.images.length === 0){
                deleteCarousal(newCarousal?.id)
            }
        }).catch((error) => {
            console.log(error.message)
        });
    }

    const deleteAlreadyCarousal = (id) => {
        const findCarousal = imagesData?.find(images => images?.id === id)
        for (const item of findCarousal?.images) {
            const imageRef = ref(storage,item)
            deleteObject(imageRef).then(()=>{
                console.log('deleted');
            })
        }
        deleteCarousal(id)
        getImagesData()
    }

    console.log('imagesData =>',imagesData);

    return (
        <Sidebar>
            <Box width={'100%'} >
                <HStack my="10px" mx="50px">
                    <Text fontSize='2xl'>Carousal Images</Text>
                    <Spacer />
                    <Button colorScheme='teal' onClick={addCarousalDisclosure.onOpen}>Add Carousal</Button>
                </HStack>
            </Box>
            <Box display={'flex'} flexWrap="wrap" gap="10px" mx="50px" mt="50px">
                {imagesData.map(data => (
                    <div className="img-container">
                        <AiFillCloseCircle className="close-icon" color="red" onClick={()=>{setSelectedID(data?.id);deleteCarousalDisclosure.onOpen()}} />
                        <Image src={data?.images?.mobileImage} width="300px" />
                    </div> 
                ))}
            </Box>
            <AddCarousalImageModal isOpen={addCarousalDisclosure.isOpen} onClose={addCarousalDisclosure.onClose} fetchAllImages={getImagesData} />
            <DeleteCarousalModal isOpen={deleteCarousalDisclosure.isOpen} getImagesData={getImagesData} setSelectedID={setSelectedID} onClose={deleteCarousalDisclosure.onClose} selectedID={selectedID} imagesData={imagesData} />
        </Sidebar>
    )
}

export default EditCarousal