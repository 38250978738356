import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    doc,
    where,
    query,
    orderBy,
    limit
} from 'firebase/firestore'
import { db } from './Firebase'


const categoryCollectionRef = collection(db,"category")

export const addCategory = newCategory => {
    return addDoc(categoryCollectionRef,newCategory)
}

export const updateCategory = (id,updatedCategory) => {
    const categoryDoc = doc(db,"category",id)
    return updateDoc(categoryDoc,updatedCategory)
}

export const getAllCategory = () => {
    return getDocs(categoryCollectionRef)
}

export const getTenCategory = () => {
    return getDocs(query(categoryCollectionRef,orderBy('createdAt','desc'),limit(10)))
}