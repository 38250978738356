
import { Text } from "@chakra-ui/react";
import React, { useRef } from "react";
import { AiOutlinePlus } from "react-icons/ai";

function FileInput({ onImageSelected }) {
  const inputRef = useRef();

  const handleOnChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = function (e) {
        onImageSelected(reader.result);
      };
    }
  };

  const onChooseImg = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={handleOnChange}
        style={{ display: "none" }}
      />

      <label className="file-input-img" onClick={onChooseImg}>
          <Text>Click to upload image</Text>
        {/* <AiOutlinePlus size={'80px'} className="icon-plus" /> */}
      </label>
    </div>
  );
}

export default FileInput;
