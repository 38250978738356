import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../Context/AuthContext"


const ProtectedRoute = () => {

    const {user} = useAuth()

    return user?.email ? <Outlet /> : <Navigate to='/' />
}

export default ProtectedRoute