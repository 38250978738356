import { Box, Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from "@chakra-ui/react"
import Compress from "react-image-file-resizer";

import { useState } from "react";
import CarousalImageCropper from "./CarousalImageCropper";
import CarousalImageSelector from "./CarousalImageSelector";
import { storage } from "../../services/Firebase";
import { useEffect } from "react";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import { addCarousalLists } from "../../services/carousal.service";


const containerStyle = {
  width: "80%",
  height: "50%",
  margin:"50px auto 20px",
  backgroundColor: "#fff",
}

const webContainerStyles = {
  width: "80%",
  height:'50%',
  margin:'50px auto 20px',
  backgroundColor:'#fff'
}

const mobileContainerStyles = {
  width: "80%",
  height:'40%',
  margin:'400px auto',
  backgroundColor:'#fff'
}


const AddCarousalImageModal = ({isOpen,onClose,fetchAllImages}) => {
    const [webImageUpload,setWebImageUpload] = useState('')
    const [mobileImageUpload,setMobileImageUpload] = useState('')
    const [croppedWebImage,setCroppedWebImage] = useState('')
    const [croppedMobileImage,setCroppedMobileImage] = useState('')
    const [webCurrentPage,setWebCurrentPage] = useState('choose-img')
    const [mobileCurrentPage,setMobileCurrentPage] = useState('choose-img')
    const [webImageLink,setWebImageLink] = useState('')
    const [mobileImageLink,setMobileImageLink] = useState('')
    const [webAspectRatio,setWebAspectRatio] = useState(1600 / 600)
      const [allImagesLink,setAllImagesLink] = useState([])
    const [mobileAspectRatio,setMobileAspectRatio] = useState(800 / 1000)

    const carousalToast = useToast()

    const onWebImageUpload = (e) => {
      const file = e.target.files[0];
      Compress.imageFileResizer(
        file, // the file from input
        1600, // width
        600, // height
        "JPEG", // compress format WEBP, JPEG, PNG
        70, // quality
        0, // rotation
        (uri) => {
            setWebImageUpload(uri)
            setWebCurrentPage("crop-img");
        },
        "base64" // blob or base64 default base64
        )
    }

    const onMobileImageUpload = (e) => {
      const file = e.target.files[0]
      Compress.imageFileResizer(
        file, // the file from input
        800, // width
        1000, // height
        "JPEG", // compress format WEBP, JPEG, PNG
        70, // quality
        0, // rotation
        (uri) => {
            setMobileImageUpload(uri)
            setMobileCurrentPage("crop-img");
        },
        "base64" // blob or base64 default base64
        )
    }

    const deleteImage = (filename) => {
      const imageRef = ref(storage,filename);

      // Delete the file
      deleteObject(imageRef).then(() => {}).catch((error) => {
          console.log(error.message)
      });
  }

      async function uploadWebImage(croppedWebImage) {
        const imageURL = await handleUploadFirebase(croppedWebImage)
        setWebImageLink(imageURL)  
      }

      async function uploadMobileImage(croppedMobileImage){
        const imageURL = await handleUploadFirebase(croppedMobileImage)
        setMobileImageLink(imageURL)
      }

    const onCloseClick = () => {
      if(webImageLink !== ''){
        deleteImage(webImageLink)
        setWebImageLink('')
      }
      if(mobileImageLink !== ''){
        deleteImage(mobileImageLink)
        setMobileImageLink('')
      }
      setWebCurrentPage('choose-img')
        setMobileCurrentPage('choose-img')
      setCroppedWebImage('')
      setCroppedMobileImage('')
      onClose()
    }

    const handleUploadFirebase = async(croppedImage) => {
      const storageref = ref(storage,'carousal/'+croppedImage?.url);
      // const uploadImagetask = uploadBytes(storageref,croppedImage)

      await uploadBytes(storageref,croppedImage?.file)
      const url = await getDownloadURL(storageref)
      return url
      // setImageLink(url)
    }

    const onCarousalAdd = async() => {
      console.log('web =>',webImageLink);
      console.log('mobile =>',mobileImageLink);
      if(webImageLink !== '' && mobileImageLink !== '') {
        const OneCarousal = {images:{webImage:webImageLink,mobileImage:mobileImageLink},created:serverTimestamp()}
        // setAllImagesLink(prev => [...prev,OneCarousal])
        try {
          await addCarousalLists(OneCarousal) 
          fetchAllImages()
          setWebImageLink('')
          setMobileImageLink('')
          setCroppedMobileImage('')
          setCroppedWebImage('')
          setWebCurrentPage('choose-img')
          setMobileCurrentPage('choose-img')
        } catch (error) {
          console.log(error.message);
        } 
        onClose()
      }else{
        carousalToast({
          title: 'Fill in all details',
          status: 'error',
          position:'top-right',
          duration: 300,
          isClosable: true,
        })
      }
    }

    
    return (
        <Modal isOpen={isOpen} onClose={onCloseClick} size="3xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign={'center'}>Add Carousal</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <CarousalImageSelector croppedImage={croppedWebImage} aspectRatio={webAspectRatio} setAspectRatio={setWebAspectRatio} uploadImage={uploadWebImage} containerStyles={webContainerStyles} setCroppedImage={setCroppedWebImage} selectedImage={webImageUpload} setSelectedImage={setWebImageUpload} currentPage={webCurrentPage} setCurrentPage={setWebCurrentPage} children={<><label className="file-input-img" >upload image for Webview,
                  <input
                        type="file"
                        accept="image/*"
                        onChange={onWebImageUpload}
                        style={{ display: "none" }}
                        />
                </label></>}>
              </CarousalImageSelector>
               <Center> <img  style={{marginBottom:'20px'}} src={croppedWebImage?.url} width="400px" /></Center>

              <CarousalImageSelector croppedImage={croppedMobileImage} aspectRatio={mobileAspectRatio} setAspectRatio={setMobileAspectRatio} uploadImage={uploadMobileImage} containerStyles={mobileContainerStyles} setCroppedImage={setCroppedMobileImage} selectedImage={mobileImageUpload} setSelectedImage={setMobileImageUpload} currentPage={mobileCurrentPage} setCurrentPage={setMobileCurrentPage} children={<><label style={{marginTop:'10px'}} className="file-input-img">upload image for mobile
                <input
                    type="file"
                    accept="image/*"
                    onChange={onMobileImageUpload}
                    style={{ display: "none" }}
                    />
                </label></>}></CarousalImageSelector>
               <Center> <img src={croppedMobileImage?.url} width="200px" /></Center>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onCloseClick}>
                Cancel
              </Button>
              <Button onClick={onCarousalAdd} variant='ghost'>Add</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
    )
}

export default AddCarousalImageModal
