import placeholder from '../assets/placeholder.png'
import categoryOne from '../assets/carousal_images/pexels1.jpg'
import categoryTwo from '../assets/carousal_images/pexels2.jpg'
import categoryThree from '../assets/carousal_images/pexels3.jpg'
import categoryFour from '../assets/carousal_images/pexels4.jpg'
import electronicsCategory from '../assets/category/electronics_category.jpg'
import jeweleryCategory from '../assets/category/jewelry_category.jpg'
import menCategory from '../assets/category/men_category.jpg'
import womenCategory from '../assets/category/women_category.jpg'


export const STORE_ACTION_TYPES = {
    ADD_ALL_PRODUCTS:'ADD_ALL_PRODUCTS',
    ADD_ONE_TO_CART:'ADD_ONE_TO_CART',
    DELETE_ONE_FROM_CART:'DELETE_ONE_FROM_CART',
    ADD_SELECTED_PRODUCT:'ADD_SELECTED_PRODUCT',
    ADD_ONE_TO_FAVOURITE:'ADD_ONE_TO_FAVOURITE',
    INCREASE_ONE_TO_QUANTITY:'INCREASE_ONE_TO_QUANTITY',
    DECREASE_ONE_TO_QUANTITY:'DECREASE_ONE_TO_QUANTITY',
    PRODUCTS_TO_BUY_FROM_CART:'PRODUCTS_TO_BUY_FROM_CART',
    TOTAL_PRICE_fROM_CART:'TOTAL_PRICE_FROM_CART',
    ADD_ADDRESS_IN_DETAIL:'ADD_ADDRESS_IN_DETAIL',
    ADD_CREDIT_CARD:'ADD_CREDIT_CARD'
}

export const filterLinks = [
    // {link:"category/all",title:"all",imageUrl:''},
    {link:"category/women",title:"Women's",imageUrl:womenCategory},
    {link:"category/men",title:"Men's",imageUrl:menCategory},
    {link:"category/electronics",title:"electronics",imageUrl:electronicsCategory},
    {link:"category/jewelery",title:"jewelery",imageUrl:jeweleryCategory},
]

export const categories = [{img:categoryOne,title:'product One',price:'4532 RUP'},{img:categoryTwo,title:'Product Two',price:'5342 RUP'},{img:categoryThree,title:'Product Three',price:'2343 RUP'},{img:categoryFour,title:'Product Four',price:'342 RUP'}]