import { Box, Button, FormControl, FormLabel, Input, Text, useStatStyles } from "@chakra-ui/react"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from "../../Context/AuthContext"


const SignUp = () => {

    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [error,setError] = useState('')
    const {signUp} = useAuth()
    const navigate = useNavigate()

    const handleSubmit = async(e) => {
        e.preventDefault()
        setError("")
        try {
            await signUp(email,password)
            if(localStorage.getItem('isFromCart')){
                navigate('/checkout')
                return
            }
            navigate('/')
        } catch (error) {
            setError(error.message)
        }
        setEmail('')
        setPassword('')
    }

    return (
        <Box maxW={'sm'} borderWidth='1px' borderRadius='lg' overflow='hidden' p={'5'} margin="auto" >
            <Text fontSize={'4xl'} fontWeight='extrabold' align="center">Sign Up</Text>
            <p>{error}</p>
            <FormControl>
                <FormLabel>Email address</FormLabel>
                <Input type='email' value={email} onChange={e =>setEmail(e.target.value)} />
            </FormControl>
            <FormControl>
                <FormLabel>Password</FormLabel>
                <Input type='Password' value={password} onChange={e =>setPassword(e.target.value)} />
            </FormControl>
            <Button width='100%' my={'4'} onClick={(e)=>handleSubmit(e)}>Sign Up</Button>
            <Text>Already have an account ?<Link to='/signin' style={{color:'blue'}}>Sign In</Link></Text>
        </Box>
    )
}

export default SignUp