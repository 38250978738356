import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useState } from 'react';
import { AiFillMinusSquare, AiFillPlusSquare, AiOutlineHeart } from 'react-icons/ai';
import { useNavigate, useParams} from 'react-router-dom'
import { useStoreContext } from '../../Context/StoreContext';
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Thumbs } from 'swiper'
import '../../product-image-slider.css'
import { STORE_ACTION_TYPES } from '../../utils/constants';
import { useAuth } from '../../Context/AuthContext';
import { getProduct } from '../../services/products.service';
import { getAllCategory } from '../../services/category.service';
const ProductDetailPage = () => {

    const params = useParams()
    const { productList , cartItems , dispatch , } = useStoreContext()

    const [selectedProduct,setSelectedProduct] = useState({})
    const [variants,setVariants] = useState({})
    const [quantity,setQuantity] = useState(1)
    const [selectedImage,setSelectedImage] = useState('')
    const [activeThumb,setActiveThumb] = useState()
    const cartToast = useToast();
    const [filteredVariant,setFilteredVariant] = useState({});
    const [categoryName,setCategoryName] = useState('')
    const [totalPrice,setTotalPrice] = useState(0)
    const [toastClick,setToastClick] = useState(0)
    const navigate = useNavigate()
    const {user} = useAuth()


    const onVariantsClick = (e) => {
        setVariants(prev => ({
            ...prev,
            [e.target.id]:e.target.value
        }))
    }

    useEffect(()=>{
        getAllCategories()
    },[selectedProduct])


    const getAllCategories = async() => {
        const getCategories = await getAllCategory()
        const categorylist = getCategories.docs.map(doc => ({...doc.data(),id:doc.id}))
        const findCategory = categorylist[0]?.categories?.find(category => category?.categoryId === selectedProduct?.categoryID)
        setCategoryName(findCategory?.categoryName)
    }

    useEffect(()=>{
        setTotalPrice(Number(selectedProduct?.prices?.[0].price) * quantity)
    },[quantity])

    const onQuantityPlus = () => {
        setQuantity(prev => prev+1)
    }

    const onQuantityMinus = () => {
        setQuantity(prev => {
            if(prev-1 === 0){
                return 1
            }else {
                return prev-1
            }
        })
    }
    useEffect(()=>{
        (async function(){
            const selectedProduct = await getProduct(params.prodID)
            console.log(selectedProduct);
            setSelectedProduct(({...selectedProduct.data(),id:selectedProduct.id,favourite:false}))
            setSelectedImage(selectedProduct?.images?.[0])
        })()
    },[])
    useEffect(()=>{
        if(selectedProduct?.variantLists?.length === Object.keys(variants)?.length){
            let filtered = selectedProduct?.variants;
            for (const key in variants) {
                filtered = filtered.filter(variant => variant.variantDetails[key] === variants[key])
            }
            setFilteredVariant(filtered?.[0])
        }
    },[variants])


    const onCartClick = (prodID) => {
        if(selectedProduct?.variantLists?.length !== Object.keys(variants)?.length){
            cartToast({
                title: 'Select Variants',
                status: 'warning',
                position:'top-right',
                duration: 300,
                isClosable: true,
            })
            return
        }
        if(quantity > filteredVariant?.quantity){
            cartToast({
                title: 'Variant Maxed Out',
                status: 'error',
                position:'top-right',
                duration: 300,
                isClosable: true,
            })
            return
        }
        const findProduct = productList?.find(prod => prod.id === prodID)
        const finalProduct = {...findProduct,quantity:quantity,selectedVariant:filteredVariant}
        const findInCart = cartItems.findIndex(item => item?.selectedVariant?.id === finalProduct?.selectedVariant?.id)
        setVariants({})
        setQuantity(1)
        if (findInCart === -1){
            dispatch({type:STORE_ACTION_TYPES.ADD_ONE_TO_CART,payload:finalProduct})
            cartToast({
                title: 'Added to Cart.',
                status: 'success',
                position:'top-right',
                duration: 300,
                isClosable: true,
            })
        }else{
            cartToast({
                title: 'Already in cart.',
                status: 'error',
                position:'top-right',
                duration: 300,
                isClosable: true,
            })
        }
    }



    const onBuyClick = () => {
        if(selectedProduct?.variantLists?.length !== Object.keys(variants)?.length){
            cartToast({
                title: 'Select Variants',
                status: 'warning',
                position:'top-right',
                duration: 300,
                isClosable: true,
            })
            return
        }
        if(quantity > filteredVariant?.quantity){
            cartToast({
                title: 'Variant Maxed Out',
                status: 'success',
                position:'top-right',
                duration: 300,
                isClosable: true,
            })
        }
        setQuantity(1)
        if(user === null){
            navigate('/signin')
            localStorage.setItem('isFromCart',true)
            dispatch({type:STORE_ACTION_TYPES.PRODUCTS_TO_BUY_FROM_CART,payload:[selectedProduct]})
            dispatch({type:STORE_ACTION_TYPES.TOTAL_PRICE_fROM_CART,payload:totalPrice})
            return
        } 
        setVariants({})
        toastClick(0)
        dispatch({type:STORE_ACTION_TYPES.PRODUCTS_TO_BUY_FROM_CART,payload:[selectedProduct]})
        dispatch({type:STORE_ACTION_TYPES.TOTAL_PRICE_fROM_CART,payload:totalPrice})
        navigate('/checkout')
    }


    return (
        <Flex width={['100%','80%']} mx="auto" flexDirection={['column','row']}   height={'91vh'} justify={'space-around'} mt="10px">
            <Flex width={['full',"60%"]}>
                <Box width={['full','75%']} mx="auto" mt={['250px','0px']}>
                <Swiper
                    loop={true}
                    spaceBetween={10}
                    navigation={true}
                    modules={[Navigation, Thumbs]}
                    grabCursor={true}
                    thumbs={{ swiper: activeThumb }}
                    className='product-images-slider'
                >
                    {
                        selectedProduct?.images?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <img src={item} alt="product images" />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                <Swiper
                    onSwiper={setActiveThumb}
                    loop={true}
                    spaceBetween={10}
                    slidesPerView={4}
                    modules={[Navigation, Thumbs]}
                    className='product-images-slider-thumbs'
                >
                    {
                        selectedProduct?.images?.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className="product-images-slider-thumbs-wrapper">
                                    <img src={item} alt="product images" />
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                </Box>
            </Flex>
            <Box width={['full',"40%"]} gap={'20px'} px="20px" mt={["0px","40px"]}>
                <Text textTransform={'capitalize'}  fontWeight={'bold'} fontSize="4xl">{selectedProduct?.Name}</Text>
                <Text>{categoryName}</Text>
                <Flex alignItems="center" mt="10px" justify={'space-between'}>
                    <Text mt="10px">&#8377; {filteredVariant?.variantPrice == undefined ? selectedProduct?.prices?.[0]?.RUPEE?.price : filteredVariant?.variantPrice}</Text>
                    <AiOutlineHeart />
                </Flex>
                <hr />
                <Flex flexDirection={'column'} my="10px">
                    {selectedProduct?.variantLists?.map(variant => (
                        <Box mt="10px">
                            <Text fontSize={'2xl'} fontWeight="bold" textTransform={'uppercase'}>{variant?.variantName}</Text>
                            <Flex gap={'10px'}>
                                {variant?.variantValues?.map(value => (
                                    <Button textTransform={'uppercase'} value={value} id={variant?.variantName}  onClick={(e)=>onVariantsClick(e)} bg={variants?.[variant?.variantName] === value ? filteredVariant?.quantity === 0 ? 'blackAlpha.200':'blackAlpha.600':''}>{value}</Button>
                                ))}
                            </Flex>
                        </Box>
                    ))}
                </Flex>
                <Flex gap={'10px'} mt="10px" flexDirection={'column'} justifyContent="center">
                    <Text mt="10px">Quantity</Text>
                    <Box display={'flex'} mt="10px" gap="10px" alignItems={'center'}>
                        <AiFillPlusSquare onClick={onQuantityPlus} size="30px" cursor={'pointer'} />
                        <Text>{quantity}</Text>
                        <AiFillMinusSquare onClick={onQuantityMinus} size="30px" cursor={'pointer'} />
                    </Box>
                </Flex>
                <Flex mt="10px" flexDirection={'column'} gap="10px" >
                    <Button mt="10px" disabled={filteredVariant?.quantity === 0 || selectedProduct?.variants?.every(variant => variant?.quantity === 0)} textTransform={'uppercase'} py="15px" colorScheme="orange" width="100%" onClick={()=>onCartClick(selectedProduct?.id)}>Add to cart</Button>
                    <Button mt="10px" disabled={filteredVariant?.quantity === 0 || selectedProduct?.variants?.every(variant => variant?.quantity === 0)} textTransform={'uppercase'} py="15px" colorScheme="red" width="100%" onClick={()=>onBuyClick()}>Buy Now</Button>
                </Flex>
                <Flex mt="10px" flexDirection={'column'}>
                    <Text mt="10px" textTransform={'uppercase'}>Description</Text>
                    <Text mt="10px">{selectedProduct?.description}</Text>
                </Flex>
            </Box>
        </Flex>
    )
}

export default ProductDetailPage