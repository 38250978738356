import { Box, Container } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getAllCarousal } from '../../services/carousal.service'
import { Carousel } from 'react-responsive-carousel'

import carouselPlaceholder from '../../assets/carousal-placeholder-2.png'



const MainCarousal = () => {
    const [imageList,setImageList] = useState([])


    useEffect(()=>{
        (async function(){
            const getAllImages = await getAllCarousal()
            const imagesList = getAllImages.docs.map(doc => ({...doc.data(),id:doc.id}))
            setImageList(imagesList)
        })()
    },[])
    
    return (
        <Carousel autoPlay width={'100%'} infiniteLoop={true} transitionTime="1000" stopOnHover={false}  showThumbs={false}  interval="10000">
            {imageList.length > 0 ? imageList?.map(src => (
                <img 
                loading='lazy'
                src={src?.images?.webImage}
                width={'100%'}
                height={'100%'}
                alt='images for carousal'
                /> 
               
            )):null
            // (
            //     <img 
            //     loading='lazy'
            //     src={carouselPlaceholder}
            //     width={'100%'}
            //     height={'100%'}
            //     alt='images for carousal'
            //     /> 
            // )
            }
        </Carousel>
    )
}
export default MainCarousal
