import { Button } from "@chakra-ui/react";
import React, { useState } from "react";
import FileInput from "./FileInput";
import ImageCropper from "./ImageCropper";

function ImageSelector({setCroppedImage,croppedImage,handleImageUpload}) {
  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState("");

  // Invoked when new image file is selected
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
  };

  // Generating Cropped Image When Done Button Clicked
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

    //   const dataURL = canvasEle.toDataURL(
        canvasEle.toBlob((file) => {
          file.name = 'cropped.jpeg';
          setCroppedImage({ file: file, url: URL.createObjectURL(file) })
          handleImageUpload({ file: file, url: URL.createObjectURL(file) })
            // setImgAfterCrop({ file: file, url: URL.createObjectURL(file) });
        //   resolve({ file: file, url: URL.createObjectURL(file) });
        }, 'image/jpeg');

    //   setCroppedImage(dataURL)
      setCurrentPage("img-cropped");
    };
  };

  // Handle Cancel Button Click
  const onCropCancel = () => {
    setCurrentPage("choose-img");
    setImage("");
  };

  return (
    <div className="container">
      {currentPage === "choose-img" ? (
        <FileInput setImage={setImage} onImageSelected={onImageSelected} />
      ) : currentPage === "crop-img" ? (
        <ImageCropper
          image={image}
          onCropDone={onCropDone}
          onCropCancel={onCropCancel}
        />
      ) : (
        <div>
          {/* <div>
            <img src={imgAfterCrop?.url} className="cropped-img" />
          </div> */}
        </div>
      )}
    </div>
  );
}

export default ImageSelector;
